import { Grid, Hidden, makeStyles, Typography } from "@material-ui/core"
import React from "react"
import { colors } from "../../colors"
import Animation from "../Animation"
import StartButton from "../Common/StartButton"
import heroImg from "./../../assets/images/Hero/hero-image.svg"
import whatsappAnimation from "./../../assets/animation/94110-whatsapp-transparent.json"
import "./index.css"
const useStyles = makeStyles((theme) => ({
  imageContainer: {
    [theme.breakpoints.down("sm")]: {
      order: 1,
    },
  },
  parentGrid: {
    marginTop: "2rem",
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column-reverse",
    },

    [theme.breakpoints.up("md")]: {},
    [theme.breakpoints.up("lg")]: {},
  },
  countUpCard1: {
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column-reverse",
    },

    [theme.breakpoints.up("md")]: {},
    [theme.breakpoints.up("lg")]: {},
  },
  doctorDetailsSingleDoc: {
    [theme.breakpoints.down("sm")]: {
      justifyContent: "center",
      marginBottom: "2rem",

      textAlign: "center",
    },
  },
}))

function Hero({ isMobile, setOpen }) {
  const classes = useStyles()
  const styles = {
    mobileHeaderImage: {
      objectFit: "contain",
      width: "95%",
      marginBottom: "1rem",
    },
    headerImage: {
      objectFit: "contain",
      width: "95%",
      marginBottom: "1rem",
    },
    headerImageDoubleDoc: {
      objectFit: "contain",
      width: "400px",
    },
    mobileHeaderImageDoubleDoc: {
      objectFit: "contain",
      width: "320px",
      marginBottom: "1rem",
    },
  }

  return (
    <Grid container justifyContent="center" className={classes.parentGrid}>
      <Grid
        container
        item
        md={6}
        // data-aos-once={true}
        // data-aos={isMobile ? "fade" : "fade-right"}
        xs={12}
        spacing={2}
        alignItems="center"
        className={classes.doctorDetailsSingleDoc}
      >
        <Grid item>
          <Typography variant="h1" style={{ fontWeight: "500" }}>
            Get your own{" "}
            <span style={{ color: colors.green, fontWeight: "800" }}>
              Digital Clinic{" "}
            </span>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: isMobile ? "center" : "flex-start",
              }}
            >
              on WhatsApp{"  "}
              <Animation
                animationData={whatsappAnimation}
                height={40}
                width={40}
                style={{ margin: "0 0 0 0.5rem", display: "flex" }}
              />
            </div>
            <span style={{ color: colors.green, fontWeight: "1000" }}>
              in less than 7 minutes! 🚀
            </span>
          </Typography>
          <Grid
            container
            xs={12}
            // direction={isMobile ? "column" : "column"}
            item
            alignItems="flex-start"
            style={{
              justifyContent: isMobile ? "center" : "flex-start",
              marginTop: "3rem",
            }}
          >
            <Grid item>
              <StartButton
                style={{
                  // marginLeft: isMobile ? "0rem" : "0.5rem",
                  padding: isMobile ? "14px 32px" : "",
                  marginRight: "1rem"
                }}
                size={isMobile ? "large" : "medium"}
                variant="contained"
                onClick={() => setOpen(true)}
                label="Start now & get a free website"
              />
              <Typography
                style={{
                  marginTop: "0.5rem",
                  marginLeft: isMobile ? "0.7rem" : "1.4rem",
                  marginBottom: "0.5rem"
                }}
              >
                Takes less than 60s ⚡
              </Typography>
            </Grid>
            <Hidden mdUp>
              <Grid
                item
                xs={12}
                style={{
                  margin: "1rem 0",
                }}
              >
                <Typography align="center">OR</Typography>
              </Grid>
            </Hidden>
            <Grid item>
              <StartButton
                size={isMobile ? "small" : "medium"}
                style={{
                  marginTop: isMobile ? "1rem" : "0",
                }}
                variant="outlined"
                onClick={() => setOpen(true)}
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Grid
        item
        md={6}
        xs={12}
        data-aos-once={true}
        data-aos={isMobile ? "slide-down" : "fade-left"}
        style={{
          alignItems: "center",
          textAlign: "center",
        }}
      >
        <img
          src={heroImg}
          alt="doctors"
          style={isMobile ? styles.mobileHeaderImage : styles.headerImage}
        ></img>
      </Grid>
    </Grid>
  )
}

export default Hero
