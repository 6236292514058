import ReactPixel from "react-facebook-pixel"

const advancedMatching = { em: "some@email.com" } // optional, more info: https://developers.facebook.com/docs/facebook-pixel/advanced/advanced-matching
const options = {
  autoConfig: true, // set pixel's autoConfig. More info: https://developers.facebook.com/docs/facebook-pixel/advanced/
  debug: false, // enable logs
}
export const initFbPIxel = () => {
  ReactPixel.init("298222402398549", advancedMatching, options)
  ReactPixel.trackCustom("initialised", { from: "here" })
}
