import {
  Grid,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableRow,
  TableHead,
  IconButton,
  Tooltip,
  Button,
} from "@material-ui/core"
import { InfoOutlined } from "@material-ui/icons"
import mixpanel from "mixpanel-browser"
import React, { useState } from "react"
import { Navigate, useNavigate } from "react-router"
import { colors } from "../../colors"
import PricingSwitch from "../PricingSwitch"

function PricingTable({ isMobile, plan, setPlan, payment = false, onClick }) {
  const navigate = useNavigate()
  const plans = [
    {
      feature: "Basic Website Creation",
      premium: true,
      pro: true,
      lite: true,
      extra:
        "Get your standard website with booking link.",
    },

    {
      feature: "Google My Business",
      premium: true,
      pro: true,
      lite: true,
      later: true,
      laterText: (
        <span
          style={{
            color: colors.blue,
            textDecoration: "underline",
            cursor: "pointer",
            fontSize: isMobile ? "10px": "20px"
          }}
          onClick={() => {
            mixpanel.track("GMB Article from Pricing")
            navigate("/blogs/how-to-set-up-your-google-business-profile")
          }}
        >
          Learn more&gt;&gt;
        </span>
      ),
      extra: "Grow your business using GMB",
    },
    {
      feature: "Paperplane Marketing",
      premium: (
        <Typography variant="h6" color="secondary" style={{display: isMobile ? null : "flex"}}>
          8<span style={{ color: "grey", marginLeft: "5px" }}> posters/month</span>
        </Typography>
      ),
      pro: (
        <Typography variant="h6" color="secondary" style={{display: isMobile ? null : "flex"}}>
          6<span style={{ color: "grey", marginLeft: "5px" }}> posters/month</span>
        </Typography>
      ),
      lite: (
        <Typography variant="h6" color="secondary" style={{display: isMobile ? null : "flex"}}>
          4<span style={{ color: "grey", marginLeft: "5px" }}> posters/month</span>
        </Typography>
      ),
      extra:
        "Weekly personalised marketing posters to help you build your brand",
    },
    {
      feature: "Whatsapp QR & Review Integration",
      premium: true,
      pro: true,
      lite: true,
      extra: "Integrated QR for Whatsapp and review",
    },
    {
      feature: "Clinic on Whatsapp",
      premium: true,
      pro: true,
      lite: true,
      // premiumText: "Extra charges",
      extra: "Clinic on Whatsapp (Basic Chatbot)",
    },
    {
      feature: "Template Website + Booking + Hosting",
      premium: true,
      pro: true,
      lite: false,
      extra:
        "Template based Website creation with booking link and hosting",
    },
    {
      feature: "Customized Whatsapp Clinic",
      premium: true,
      pro: true,
      lite: false,
      extra: "WhatsApp Clinic with Customizable Chatbot and Description",
    },

    {
      feature: "Automated Call Reminder (IVR)",
      premium: true,
      pro: true,
      // later: true,
      lite: false,
      extra:
        "Call Reminder on Appointments",
    },
    {
      feature: "Website WhatsApp Connect",
      pro: true,
      premium: true,
      lite: false,
      extra:
      `"Connect on Whatsapp" Button inetgrated with your customized website`,
    },
    {
      feature: "Social Profile Creation & Management",
      premium: true,
      pro: true,
      lite: false,
      extra: "End-to-End Solutions for Facebook and Instagram Profile Creation and Management",
    },
    {
      feature: "Sponsored Medical Ads",
      premium: true,
      pro: true,
      lite: false,
      extra:
        "Targeted Advertising on Facebook and Google with Doctor-Sponsored Ad Budget",
    },
    {
      feature: "LinkedIn Profile Support",
      premium: true,
      pro: true,
      lite: false,
      extra:
        "Professional LinkedIn Profile Creation and Management Services",
    },
    {
      feature: "Social Media Manager",
      premium: true,
      pro: true,
      lite: false,
      extra:
        "Expert Social Media/Brand Manager Focused on Growing Your Presence",
    },
    {
      feature: "Pharmacy on Whatsapp",
      premium: true,
      pro: true,
      lite: false,
      extra:
        "Instant Pharmacy Support through Whatsapp",
    },
    {
      feature: "Lab & Diagnostics",
      premium: true,
      pro: true,
      lite: false,
      extra:
        "Streamlined Lab and Diagnostics Processes on Whatsapp",
    },
    {
      feature: "ABHA ID Creation",
      premium: true,
      pro: true,
      lite: false,
      extra:
        "WhatsApp Assistant for ABHA ID Creation",
    },
    {
      feature: "ABHA ID Incentives",
      premium: true,
      pro: true,
      comingSoon: true,
      later: true,
      lite: false,
      extra:
        "Government Incentives for ABHA ID",
    },
    {
      feature: "Dedicated Relationship Manager",
      premium: true,
      pro: true,
      lite: false,
      extra:
        "24-Hour Access to a Personal Relationship Manager for Continuous Assistance",
    },
    {
      feature: "Patient Testimonials",
      premium: (
        <Typography variant="h6" color="secondary">
          4<span style={{ color: "grey" }}> posts</span>
        </Typography>
      ),
      pro: false,
      lite: false,
      extra:
        "Patient Testimonials on your Website",
    },
    {
      feature: "Blogs",
      premium: (
        <Typography variant="h6" color="secondary">
          6<span style={{ color: "grey" }}> blogs</span>
        </Typography>
      ),
      pro: false,
      lite: false,
      extra:
        "Blogs Integrated into the Website",
    },
    {
      feature: "Backlink Creation",
      premium: true,
      pro: false,
      lite: false,
      extra:
        "Create your own backlinks",
    },
    {
      feature: "Custom Online Brand",
      premium: true,
      pro: false,
      lite: false,
      extra:
        "Expert Online Brand Design Services: Enhancing and Revitalizing Existing Online Presence",
    },
    {
      feature: "Offline Hoarding",
      premium: true,
      pro: false,
      lite: false,
      extra:
        "Traditional Hoarding for Effective Offline Marketing",
    },
    {
      feature: "Presonalized Mobile App",
      premium: true,
      pro: false,
      lite: false,
      extra:
        "Get your own Mobile Application on Android & iOS",
    },
    {
      feature: "Health Camps",
      premium: true,
      pro: false,
      lite: false,
      extra:
        "Organize and Conduct Health Camps virtually.",
    },
  ]
  const styles = {
    feature: {},
    mobileSpan: {
      background: colors.gradient,
      WebkitBackgroundClip: "text",
      WebkitTextFillColor: "transparent",
      backgroundClip: "text",
    },
    lite: {
      textAlign: "center",
      minWidth: isMobile ? "80px" : "212px",
    },
    premium: {
      textAlign: "center",
      minWidth: isMobile ? "80px" : "212px",
    },
  }

  return (
    <Grid container item xs={12} md={10} style={{maxWidth: "100%", overflowX: "auto"}} data-aos="flip-up">
      {/* <Grid
        item
        xs={12}
        container
        direction="row"
        spacing={3}
        justify="center"
        alignItems="center"
        style={{ margin: "1.5rem 0" }}
      >
        <Grid item>
          <Typography
            variant="h3"
            style={{ color: !plan ? "inherit" : "#CBC8C7" }}
          >
            Quarterly
          </Typography>
        </Grid>
        <Grid item>
          {" "}
          <PricingSwitch plan={plan} setPlan={setPlan} />
        </Grid>{" "}
        <Grid item style={{ position: "relative" }}>
          <Typography
            style={{ position: "absolute", right: "0", top: "-0.5rem" }}
            variant="body1"
          >
            {" "}
            <i class="fa-solid fa-arrow-down-long" style={{}}></i> Save{" "}
            <span style={{ color: colors.blue }}>30%</span>
          </Typography>
          <Typography
            variant="h3"
            color={plan ? "inherit" : "textSecondary"}
            style={plan ? styles.mobileSpan : {}}
          >
            Annually
          </Typography>
        </Grid>
      </Grid> */}

      {/* <Grid
        item
        xs={12}
        md={10}
        style={{
          marginBottom: "1rem",
          // marginLeft: isMobile ? "auto": "0rem",
          textAlign: "center",
          justifyContent: "center",
        }}
        container
        data-aos="flip-up"
      > */}
        <Table>
          <TableHead>
            <TableRow>
              <TableCell></TableCell>
              <TableCell  align="center">
                <Typography variant={isMobile ? "body1": "h5"}>
                  PILOT
                  <br />
                  <span style={{ color: "grey", fontSize: isMobile ? "12px" : "16px" }}>
                    {plan ? "12 months" : "3 months"}
                  </span>
                  <br />
                  <Typography variant={isMobile ? "h6": "h4"}>
                    ₹20,000
                  </Typography>
                </Typography>
              </TableCell>
              <TableCell align="center">
              <Typography variant={isMobile ? "body1": "h5"}>
                  PRO <br />
                  <span style={{ color: "grey", fontSize: isMobile ? "12px" : "16px" }}>
                    {plan ? "12 months" : "3 months"}
                  </span>
                  <br />
                  <Typography variant={isMobile ? "h6": "h4"}>
                    {" "}
                    ₹40,000
                  </Typography>
                </Typography>
              </TableCell>
              <TableCell align="center">
              <Typography variant={isMobile ? "body1": "h5"}>
                  SUPERDOC <br />
                  <span style={{ color: "grey", fontSize: isMobile ? "12px" : "16px" }}>
                    {plan ? "12 months" : "3 months"}
                  </span>
                  <br />
                  <Typography variant={isMobile ? "h6": "h4"}>
                    {" "}
                    ₹90,000
                  </Typography>
                </Typography>
              </TableCell>
            </TableRow>
          </TableHead>
            {plans?.map((ele) => (
              <TableRow>
                <TableCell>
                  <Typography variant={isMobile ? "subtitle2" : "h6"} style={{fontSize: isMobile ? "12px": "22px"}}>
                    {ele.feature}

                    {!isMobile ? (
                      <Tooltip title={ele.extra} placement="top-start">
                        <IconButton>
                          <InfoOutlined style={{ fontSize: "16px" }} />
                        </IconButton>
                      </Tooltip>
                    ) : (
                      <Typography color="textSecondary" variant="subtitle1">
                        {ele?.later ? (
                          <span
                            style={{
                              fontSize: isMobile ? "10px" : "13px",
                              color: colors.green,
                            }}
                          >
                            {ele?.laterText}
                            {ele?.laterText ? <br /> : null}
                            {ele?.comingSoon ? "*Coming Soon" : null}
                            <br />
                          </span>
                        ) : null}

                        {ele.extra}
                      </Typography>
                    )}
                  </Typography>
                  {!isMobile ? (
                    ele?.later ? (
                      <span
                        style={{
                          fontSize: isMobile ? "11px" : "13px",
                          color: colors.green,
                        }}
                      >
                        {ele?.laterText}
                        {ele?.laterText && ele?.comingSoon ? <br /> : null}
                        {ele?.comingSoon ? "*Coming Soon" : null}
                        <br />
                      </span>
                    ) : null
                  ) : null}
                </TableCell>
                <TableCell align="center">
                <Typography variant="h6" style={{display: "flex", justifyContent: "center"}}>
                    {" "}
                    {typeof ele.lite == "boolean" ? (
                      ele.lite ? (
                        <i
                          className="fa-solid fa-check "
                          style={{ fontSize: isMobile ? "14px" : "24px" }}
                        ></i>
                      ) : (
                        <i
                          class="fa-solid fa-xmark"
                          style={{ fontSize: isMobile ? "14px" : "24px" }}
                        ></i>
                      )
                    ) : (
                      ele.lite
                    )}
                  </Typography>
                </TableCell>
                <TableCell
                  style={{
                    borderLeft: "2px solid #f3f3f3",
                    borderRight: "2px solid #f3f3f3",
                  }}
                  align="center"
                >
                  <Typography variant="h6" style={{display: "flex", justifyContent: "center"}}>
                    {" "}
                    {typeof ele.pro === "boolean" ? (
                      ele.pro ? (
                        <i
                          class="fa-solid fa-check"
                          style={{ fontSize: isMobile ? "14px" : "24px" }}
                        ></i>
                      ) : (
                        <i
                          class="fa-solid fa-xmark"
                          style={{ fontSize: isMobile ? "14px" : "24px" }}
                        ></i>
                      )
                    ) : (
                      ele.pro
                    )}
                    {ele.proText ? (
                      <>
                        {" "}
                        <br />
                        <span style={{ color: "grey" }}>{ele.proText}</span>
                      </>
                    ) : null}
                  </Typography>
                </TableCell>

                <TableCell
                  style={{
                    borderLeft: "2px solid #f3f3f3",
                    borderRight: "2px solid #f3f3f3",
                  }}
                  align="center"
                >
                  <Typography variant="h6" style={{display: "flex", justifyContent: "center"}}>
                    {" "}
                    {typeof ele.premium === "boolean" ? (
                      ele.premium ? (
                        <i
                          class="fa-solid fa-check"
                          style={{ fontSize: isMobile ? "14px" : "24px" }}
                        ></i>
                      ) : (
                        <i
                          class="fa-solid fa-xmark"
                          style={{ fontSize: isMobile ? "14px" : "24px" }}
                        ></i>
                      )
                    ) : (
                      ele.premium
                    )}
                    {ele.premiumText ? (
                      <>
                        {" "}
                        <br />
                        <span style={{ color: "grey" }}>{ele.premiumText}</span>
                      </>
                    ) : null}
                  </Typography>
                </TableCell>
              </TableRow>
            ))}
        </Table>
      {/* </Grid> */}
    </Grid>
  )
}

export default PricingTable
