import React, { useEffect } from "react"
import ReactDOM from "react-dom"
import "./index.css"
import App from "./App"
import reportWebVitals from "./reportWebVitals"
import { BrowserRouter } from "react-router-dom"
import { MuiThemeProvider } from "@material-ui/core"
import theme from "./theme"
import mixpanel from "mixpanel-browser"
mixpanel.init("89fee75eac77fbd386f469a58a7806f4", {
  ignore_dnt: true,
})
mixpanel.track("Website Opened")

ReactDOM.render(
  <BrowserRouter>
    <MuiThemeProvider theme={theme}>
      <React.StrictMode>
        <App />
      </React.StrictMode>
    </MuiThemeProvider>
  </BrowserRouter>,
  document.getElementById("root")
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
