import React from "react"
import Carousel from "react-multi-carousel"
import "react-multi-carousel/lib/styles.css"
import "./index.css"
import Quote from "./../../assets/images/Testimonials/Vector.png"
import drNaman from "./../../assets/images/Testimonials/drnaman-min_11zon.19b7689bd0e66b195f98.png"
import drMohita from "./../../assets/images/Testimonials/mohita.png"
import drNidhika from "./../../assets/images/Testimonials/nidhika-min_11zon.e054f0da091438f3d3b4.png"
import drPriyanka from "./../../assets/images/Testimonials/nidhika-min_11zon.e054f0da091438f3d3b4.png"
import drParam from "./../../assets/images/Testimonials/paramjeetimg.cf6635cfdcf3bc4e1751.png"
import drViral from "./../../assets/images/Testimonials/Viralimage.c35462908284482ef2a3.png"
import { Avatar, Grid, Typography, Hidden, Button } from "@material-ui/core"
import { colors } from "./../../colors/index"
import {
  DockTwoTone,
  FiberManualRecord,
  FiberManualRecordOutlined,
} from "@material-ui/icons"
import StartButton from "../Common/StartButton"
import mixpanel from "mixpanel-browser"
const responsive = {
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 2,
    slidesToSlide: 1, // optional, default to 1.
    partialVisibilityGutter: 40,
  },
  tablet: {
    breakpoint: { max: 1024, min: 450 },
    items: 1,
    slidesToSlide: 1, // optional, default to 1.
    partialVisibilityGutter: 30,
  },
  mobile: {
    breakpoint: { max: 450, min: 0 },
    items: 1,
    partialVisibilityGutter: 30,
    slidesToSlide: 1, // optional, default to 1.
  },
}
const CustomDot = ({ onMove, index, onClick, active }) => {
  return (
    <li className={active ? "active" : "inactive"} onClick={() => onClick()}>
      <FiberManualRecord
        fontSize="small"
        style={{ color: active ? "#7C90FF" : "#C4C4C4" }}
      />
    </li>
  )
}
export default function Testimonials({ isMobile }) {
  function randomColor() {
    let hex = Math.floor(Math.random() * 0xffffff)
    let color = "#" + hex.toString(16)

    return color
  }
  const data = [
    {
      text: (
        <span>
          {" "}
          Paperplane provides a{" "}
          <span style={{ color: colors.primary, fontWeight: "700" }}>
            smooth digital prescription writing
          </span>{" "}
          interface and I can directly share all those{" "}
          <span style={{ color: colors.primary, fontWeight: "700" }}>
            prescriptions over WhatsApp
          </span>{" "}
          to give my patients a{" "}
          <span style={{ color: colors.primary, fontWeight: "700" }}>
            seamless consultation experience!
          </span>
        </span>
      ),
      name: "Dr. Naman Kanodia",
      extra: "SS Pedia Ortho Centre, Uttar Pradesh",
      img: drNaman,
      link: "https://go.paperplane.health/ssortho",
    },

    // {
    //   text: "Paperplane WhatsApp Clinic provides an 'All in one' patient management and patient wellness  ecosystem, I can easily keep a track on all the prescriptions, follow up dates etc and plan out my calendar accordingly.",
    //   name: "Dr Priyanka Bhargava",
    //   extra: "Uttar Pradesh",
    //   img: drPriyanka,
    // },

    {
      text: (
        <span>
          Paperplane WhatsApp Clinic assist me to create my own{" "}
          <span style={{ color: colors.primary, fontWeight: "700" }}>
            social media brand.{" "}
          </span>
          I can provide my patients with best care by sending them
          <span style={{ color: colors.primary, fontWeight: "700" }}>
            {" "}
            personalised and automated medicine & follow up reminders
          </span>{" "}
          while maintaining a proper patient database in my mobile phone itself.
        </span>
      ),
      name: "Dr. Mohita Mehrotra",
      extra: "Growing Smiles, Uttar Pradesh",
      img: drMohita,
      link: " https://go.paperplane.health/Drmohita",
    },
    {
      text: (
        <span>
          <span style={{ color: colors.primary, fontWeight: "700" }}>
            Website delivery was super fast
          </span>{" "}
          adding on to which my
          <span style={{ color: colors.primary, fontWeight: "700" }}>
            {" "}
            Paperplane's WhatsApp Assistant
          </span>{" "}
          never forgets to send follow up reminders to my patients so they never
          miss out on any vaccination.
        </span>
      ),
      name: "Dr. Nidhika Pandey",
      extra: "SS Pedia Ortho Care, Uttar Pradesh",
      img: drNidhika,
      link: "https://go.paperplane.health/ssortho",
    },
    {
      text: (
        <span>
          I can manage patients from{" "}
          <span style={{ color: colors.primary, fontWeight: "700" }}>
            multiple offline clinics{" "}
          </span>
          through Paperplane WhatsApp Clinic and also{" "}
          <span style={{ color: colors.primary, fontWeight: "700" }}>
            start my own teleconsultation{" "}
          </span>
          service for reaching out to patients{" "}
          <span style={{ color: colors.primary, fontWeight: "700" }}>
            Pan India.
          </span>
        </span>
      ),
      name: "Dr. Paramjit Singh Juneja",
      extra: "Uttar Pradesh",
      img: drParam,
      link: "https://go.paperplane.health/a1qCpR",
    },
    {
      text: (
        <span>
          Paperplane WhatsApp Clinic provides a{" "}
          <span style={{ color: colors.primary, fontWeight: "700" }}>
            unique OPD Management solution
          </span>{" "}
          over WhatsApp which primarily focuses on patient rentention and
          enables me to schedule future appointments with my Patients provide
          them with{" "}
          <span style={{ color: colors.primary, fontWeight: "700" }}>
            best digital care{" "}
          </span>
          and maintain a
          <span style={{ color: colors.primary, fontWeight: "700" }}>
            {" "}
            systematic patient database.
          </span>
        </span>
      ),
      name: "Dr. Viralsinhraj",
      extra: "Raj Diabetes Care, Gujarat",
      img: drViral,
      link: " https://go.paperplane.health/drviral",
    },
  ]
  return (
    <div
      className="feedback-container"
      data-aos="fade-up"
      data-aos-delay={300}
      data-aos-once={true}
    >
      <Typography variant="h1">Why do doctors love us? 💌</Typography>

      <div className="feedback-carousel-container">
        <Carousel
          responsive={responsive}
          infinite={true}
          swipeable={true}
          autoPlay={true}
          transitionDuration={500}
          keyBoardControl={true}
          autoPlaySpeed={5000}
          removeArrowOnDeviceType={["tablet", "mobile", "desktop"]}
          className="carousel-container"
          showDots={true}
          customDot={<CustomDot />}
        >
          {data.map((ele, idx) => (
            <div
              style={{
                padding: "1rem",
                marginBottom: "2rem",
              }}
            >
              <Grid
                container
                spacing={2}
                direction="row"
                justifyContent="space-between"
                style={{
                  height: isMobile ? "600px" : "500px",
                  lineHeight: "35px",
                  padding: "2rem",
                  boxShadow: "0px 4px 20px rgba(0, 0, 0, 0.1)",
                  background: "#fff",
                  textAlign: "left",
                  borderRadius: "14px",
                }}
              >
                <Grid item xs={12}>
                  <img src={Quote} alt="quote"></img>
                </Grid>
                <Grid item xs={12} md={10}>
                  <Typography
                    variant="h6"
                    color="textSecondary"
                    style={{ fontFamily: "Montserrat" }}
                  >
                    {ele.text}
                  </Typography>
                </Grid>
                <Grid
                  container
                  item
                  justifyContent="flex-start"
                  style={{ flexWrap: isMobile ? "wrap" : "nowrap" }}
                  alignItems="center"
                  direction="row"
                >
                  <Grid item>
                    <img
                      src={ele.img}
                      alt={ele.name}
                      style={{
                        height: isMobile ? "100px" : "100px",
                        width: isMobile ? "100px" : "100px",
                        borderRadius: "50%",
                        marginRight: "1rem",
                      }}
                    />
                  </Grid>
                  <Grid item>
                    <Typography
                      variant="h5"
                      style={{ fontFamily: "Montserrat" }}
                    >
                      {" "}
                      {ele.name}
                    </Typography>

                    <Typography
                      variant="h6"
                      color="textSecondary"
                      style={{ fontFamily: "Montserrat" }}
                    >
                      {" "}
                      {ele.extra}
                    </Typography>
                    <StartButton
                      startIcon={<i class="fa-brands fa-whatsapp"></i>}
                      style={{
                        background: "#25D366",
                        color: "white",
                        marginTop: "1rem",
                      }}
                      size="small"
                      label="TRY NOW !"
                      onClick={() => {
                        window.open(ele.link)
                        mixpanel.track(`${ele.name} Try Now Clicked`)
                      }}
                    >
                      {" "}
                    </StartButton>
                  </Grid>
                </Grid>
              </Grid>
            </div>
          ))}
        </Carousel>
      </div>
    </div>
  )
}
