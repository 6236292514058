export const displayRazorpay = (options, setPaymentStep) => {
  const rzp1 = new window.Razorpay(options)
  rzp1.on("payment.failed", function (response) {
    setPaymentStep("error")
  })
  rzp1.open()
}

export const loadRazorPay = () => {
  const script = document.createElement("script")
  script.src = "https://checkout.razorpay.com/v1/checkout.js"
  document.body.appendChild(script)

  script.onload = () => {}
}

export const defaultOptions = {
  key: "rzp_live_GO71P1BscARhu6",
  amount: "50000", // Amount is in currency subunits. Default currency is INR. Hence, 50000 refers to 50000 paise
  currency: "INR",
  name: "Paperplane Communications",
  description: "Subscription",
  image: "",
  //This is a sample Order ID. Pass the `id` obtained in the response of Step 1
  handler: function (response) {
    alert("Transaction Successful")
  },

  theme: {
    color: "#5E72E4",
  },
  retry: {
    enabled: true,
    max_count: 3,
  },
  redirect: false,
  timeout: 600,
}
