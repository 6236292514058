import { Button, Grid, Hidden, TextField, Typography } from "@material-ui/core";
import { Email, Phone } from "@material-ui/icons";
import React, { useState } from "react";
import ContactInput from "../Common/ContactInput";
import app from "./../../assets/images/GetApp/Vectary texture.png";
import playStore from "./../../assets/images/GetApp/Store=Google Play, Style=Colorfull-Black.png";
import appStore from "./../../assets/images/GetApp/Store=App Store, Style=Colorfull-Black.png";
import { colors } from "../../colors";
import mixpanel from "mixpanel-browser";
function GetApp({ isMobile = false, isTab = false }) {
  const styles = {
    mobilePlayStore: {
      cursor: "pointer",
      height: "45px",
      objectFit: "contain",
      marginLeft: "-1rem",
      marginTop: "2rem",
    },
    playStore: {
      cursor: "pointer",

      height: "40px",
      objectFit: "contain",
      marginTop: "2rem",

      marginLeft: "-0.5rem",
    },
    appStore: {
      cursor: "pointer",

      height: "40px",
      objectFit: "contain",
      marginTop: "2rem",

      marginLeft: "0.5rem",
    },
    mockup: {
      width: "28%",
      position: "absolute",
      top: "-4rem",
      height: "500px",
      objectFit: "contain",
      right: "-8rem",
    },
    mobileMockup: {
      width: "100%",
      objectFit: "contain",
    },
  };
  return (
    <Grid
      container
      justifyContent="center"
      style={{ marginTop: "2rem" }}
      data-aos="fade-up"
      data-aos-once={true}
      data-aos-delay={200}
    >
      <Grid
        item
        xs={12}
        md={12}
        style={{
          height: isMobile ? "inherit" : "100vh",
          display: "flex",
          alignItems: "center",
          margin: isMobile ? "50px 0px" : "0px",
          marginRight: isMobile ? "0" : "8%",
        }}
      >
        <div
          style={{
            background: "#FFFFFF",
            boxShadow: "0px 4px 20px rgba(0, 0, 0, 0.1)",
            borderRadius: "18px",

            position: "relative",
          }}
        >
          <Grid container>
            <Grid
              container
              spacing={1}
              xs={12}
              md={6}
              style={{
                // boxShadow: isMobile
                //   ? "none"
                //   : "-15px 10px 6px -3px rgba(0, 0, 0, 0.1)",
                // borderRadius: isMobile ? "0px" : "18px",

                padding: "3rem",
                // borderRadius: isMobile ? "18px" : "0px",
                // boxShadow: isMobile
                //   ? "0px 4px 20px rgba(0, 0, 0, 0.1)"
                //   : "0px 40px 0px rgba(0, 0, 0, 0.0)",

                // boxShadow: isMobile
                //   ? "rgba(0, 0, 0, 0.05) 0px 0px 0px 1px"
                //   : "0",
              }}
            >
              <Grid
                item
                xs={12}
                container
                direction="column"
                style={{ textAlign: isMobile ? "center" : "left" }}
              >
                <Typography variant="h1" style={{ marginBottom: "0.5rem" }}>
                  Get started now
                </Typography>
                {/* <div
                  style={{
                    fontSize: isMobile ? "20px" : "25px",
                    color: "#fff",
                    fontFamily: "Open Sans",
                    padding: "0.30rem 0.5rem",
                    margin: isMobile ? "0.25rem auto" : "0",
                    width: "fit-content",
                    background: colors.gradient,
                    borderRadius: "11px",
                  }}
                >
                  FREE for first 1000 users!
                </div> */}
              </Grid>
              <Grid container xs={12} style={{ marginTop: "2rem" }}>
                <ContactInput isMobile={isMobile}></ContactInput>
              </Grid>
            </Grid>
            <Grid
              item
              md={6}
              xs={12}
              container
              alignItems="center"
              style={{
                background: isMobile ? "#4D63DD" : "#fff",
                borderRadius: isMobile ? "0px 0 18px 18px" : "0px",
                marginTop: isMobile ? "2rem" : "0",
              }}
            >
              <Grid
                item
                md={12}
                container
                alignItems="center"
                xs={7}
                style={{
                  background: isMobile ? "none" : "#4D63DD",
                  height: "100%",
                  padding: isMobile ? "2rem" : "0 0 0 8rem",
                }}
              >
                <Grid item xs={12} md={7}>
                  <Typography
                    variant="h3"
                    style={{
                      color: "rgba(255, 255, 255, 0.85)",
                      zIndex: "200",
                      lineHeight: isMobile ? "30px" : "inherit",
                      fontWeight: "normal",
                    }}
                  >
                    The Paperplane app is now <br />
                    <span
                      style={{
                        fontWeight: "700",
                        color: "#fff",
                        paddingTop: "2rem",
                      }}
                    >
                      Available on Android & iOS!
                    </span>
                  </Typography>
                  <img
                    onClick={() => {
                      mixpanel.track("Play store getapp clicked");
                      window.open("https://play.google.com/store/apps/details?id=paperplane.health.app");
                    }}
                    src={playStore}
                    style={isMobile ? styles.mobilePlayStore : styles.playStore}
                    alt="https://play.google.com/store/apps/details?id=paperplane.health.app"
                  ></img>
                  <img
                    onClick={() => {
                      mixpanel.track("App store getapp clicked");
                      window.open("https://apps.apple.com/in/app/paperplane-clinic/id1620864108");
                    }}
                    src={appStore}
                    style={isMobile ? styles.mobilePlayStore : styles.appStore}
                    alt="https://apps.apple.com/in/app/paperplane-clinic/id1620864108"
                  ></img>
                </Grid>
              </Grid>
              <Hidden smDown>
                <img
                  src={app}
                  alt="appMockup"
                  style={isMobile ? styles.mobileMockup : styles.mockup}
                ></img>
              </Hidden>
              <Hidden mdUp>
                <Grid item xs={4} style={{ padding: "0.25rem" }}>
                  <img
                    src={app}
                    alt="appMockup"
                    style={styles.mobileMockup}
                  ></img>
                </Grid>
              </Hidden>
            </Grid>
          </Grid>
        </div>
      </Grid>
    </Grid>
  );
}

export default GetApp;
