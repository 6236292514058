import {
  Backdrop,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  makeStyles,
  Typography,
} from "@material-ui/core"
import axios from "axios"
import React, { useState } from "react"
import { useEffect } from "react"
import { Navigate, Route, Routes, useNavigate, useParams } from "react-router"
import { toast } from "react-toastify"
import Animation from "../../Components/Animation"
import loadingAnimation from "./../../assets/animation/loadingPayment.json"
import errorAnimation from "./../../assets/animation/error.json"
import PricingTable from "../../Components/PricingTable"
import {
  defaultOptions,
  displayRazorpay,
  loadRazorPay,
} from "../../helpers/razorpayHelper"
// import dashboardSocket from "../../socket"
import confettiAnimation from "./../../assets/animation/confetti.json"
import successAnimation from "./../../assets/animation/success.json"

import SubscriptionPayment from "../../Components/SubscriptionPayment"
import StartButton from "../../Components/Common/StartButton"
import EnterpriseForm from "../../Components/EnterpriseForm"
import mixpanel from "mixpanel-browser"
const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
}))
function Subscription({ isMobile }) {
  const navigate = useNavigate()
  const classes = useStyles()
  const [check, setCheck] = useState(false)
  const [paymentStatus, setPaymentStatus] = useState(null)
  const [plan, setPlan] = useState(true)
  const [state, setState] = useState({})
  const [loading, setLoading] = useState(false)
  const [open, setOpen] = useState(false)
  const [subscriptionId, setSubscriptionId] = useState(null)
  console.log("state", state)
  const getActivePaymentStep = (paymentStatus) => {
    switch (paymentStatus) {
      case "success":
        return (
          <Grid
            item
            md={8}
            xs={12}
            justifyContent="center"
            spacing={3}
            container
          >
            <Grid item xs={12}>
              <Animation animationData={successAnimation}></Animation>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="h3" align="center" color="textSecondary">
                <span style={{ color: "green" }}>
                  {" "}
                  Hey {state.name}, your payment has been received.
                </span>
                <br />
                Razorpay Order Id: {state.razorpayOrderId}
                <br />
                You must've received an invoice on WhatsApp.
                <br />
              </Typography>
            </Grid>
            <Grid
              container
              item
              xs={12}
              justifyContent="center"
              spacing={3}
              color="textSecondary"
            >
              <Grid item>
                <Button
                  color="primary"
                  startIcon={<i class="fas fa-desktop"></i>}
                  variant="outlined"
                  onClick={() => setPaymentStatus(null)}
                >
                  Dashboard
                </Button>
              </Grid>
              <Grid item>
                <Button
                  color="primary"
                  startIcon={<i class="fas fa-mobile"></i>}
                  variant="contained"
                  onClick={() => setPaymentStatus(null)}
                >
                  Mobile App
                </Button>
              </Grid>
            </Grid>
          </Grid>
        )

      case "verify":
        return (
          <Grid item md={8} xs={12} justifyContent="center" spacing={3}>
            <Grid item xs={12}>
              <Animation animationData={loadingAnimation}></Animation>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="h3" align="center" color="textSecondary">
                Hey {state.name}, verifying your payment.
                <br />
                <span style={{ color: "red" }}>
                  Do not press the back button
                </span>
              </Typography>
            </Grid>
          </Grid>
        )

      case "error":
        return (
          <Grid
            container
            item
            xs={12}
            md={8}
            justifyContent="center"
            spacing={3}
            style={{
              // boxShadow: "rgba(149, 157, 165, 0.2) 0px 8px 24px",
              borderRadius: "18px",
            }}
          >
            <Grid item xs={12}>
              <Animation animationData={errorAnimation}></Animation>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="h3" align="center" color="textSecondary">
                Hey, {state.name}, the payment could not be processed.
                <br /> If the amount has already been deducted, please raise an
                issue .
              </Typography>
            </Grid>
            <Grid container item xs={12} justifyContent="center" spacing={3}>
              <Grid item>
                <Button
                  color="primary"
                  startIcon={<i class="fas fa-hand-paper"></i>}
                  variant="outlined"
                  onClick={() => setPaymentStatus(null)}
                >
                  Raise Issue
                </Button>
              </Grid>
              <Grid item>
                <Button
                  color="primary"
                  startIcon={<i class="fas fa-redo"></i>}
                  variant="contained"
                  onClick={() => setPaymentStatus(null)}
                >
                  Retry
                </Button>
              </Grid>
            </Grid>
          </Grid>
        )
      default:
        return (
          <Grid container xs={12} item justifyContent="center">
            <Grid item xs={12}>
              <Typography variant="h3" align="center">
                Hey, {state?.name}, please select a plan.
              </Typography>

              <Typography
                align="center"
                variant="h6"
                color="textSecondary"
                style={{ marginTop: "0.5rem" }}
              >
                <br />
                *The following prices are valid for{" "}
                <strong>individual practitioners</strong> only.
                <br /> Click{" "}
                <span
                  onClick={() => setOpen(true)}
                  style={{ color: "blue", cursor: "pointer" }}
                >
                  here
                </span>{" "}
                to know more about our enterprise plans.
              </Typography>
            </Grid>
            <PricingTable
              plan={plan}
              setPlan={setPlan}
              onClick={(plan) => handlePayNow(plan)}
              isMobile={isMobile}
              payment={true}
            />
            <Grid
              item
              xs={12}
              style={{ textAlign: "center", margin: "1rem 0" }}
            >
              <Typography variant="h3" style={{ marginBottom: "1rem" }}>
                For enterprises 🏨:
              </Typography>
              <StartButton
                label="Contact Now"
                onClick={() => setOpen(true)}
              ></StartButton>
            </Grid>
          </Grid>
        )
    }
  }
  const checkPaymentStatus = (subscriptionId) => {
    console.log("123", subscriptionId, state.name)
    if (subscriptionId) {
      setPaymentStatus("verify")
      axios
        .post(
          `https://pplane-api-prod.paperplane.health/api/v1/doctor/check-subscription-payment/${subscriptionId}`,
          {}
        )
        .then((res) => {
          if (res.data.success) {
            if (res.data.data) {
              mixpanel.track("Paid for Subscription", { ...state })
              setPaymentStatus("success")
            } else {
              setPaymentStatus("error")
            }
          } else {
            setPaymentStatus("error")
          }
        })
        .catch((err) => {
          setPaymentStatus("error")
          toast.error("Some error occured")
        })
    } else {
      setPaymentStatus("verify")
    }
  }
  useEffect(() => {
    if (paymentStatus === "verify") checkPaymentStatus(subscriptionId)
  }, [paymentStatus])
  const handlePayNow = (type) => {
    toast.info("Payment dialog will open in a few seconds")
    axios
      .post(
        `https://pplane-api-prod.paperplane.health/api/v1/doctor/subscription-payment`,
        {
          plan: type,
          duration: plan ? "annually" : "quarterly",
          docId: state?.docId,
        }
      )
      .then((res) => {
        if (res.data.success) {
          mixpanel.track("Pay Now Clicked", {
            ...state,
            subscriptionId: res.data.data.subscriptionId,
            razorpayOrderId: res.data.data.razorpayOrderId,
            plan: type,
            duration: plan ? "annually" : "quarterly",
          })

          setSubscriptionId(res.data.data.subscriptionId)
          setState((prev) => ({
            ...prev,
            subscriptionId: res.data.data.subscriptionId,
            razorpayOrderId: res.data.data.razorpayOrderId,
            plan: type,
            duration: plan ? "annually" : "quarterly",
          }))
          setTimeout(() => {
            displayRazorpay({
              ...defaultOptions,

              description:
                type.toUpperCase() +
                "- " +
                (plan ? "Annual" : "Quarterly") +
                " Subscription",
              handler: function (response) {
                console.log("res", response)

                checkPaymentStatus(subscriptionId)
              },
              order_id: res?.data?.data.razorpayOrderId,
              prefill: {
                name: state.name,
                contact: +91 + state.phoneNo,
                email: "support@paperplanetech.co",
              },
              modal: {
                ondismiss: () => {
                  toast.warning("Payment cancelled")
                },
              },
            })
          }, 3000)
        }
      })
  }
  const { id } = useParams()
  useEffect(() => {
    axios
      .get(
        `https://pplane-api-prod.paperplane.health/api/v1/ai/fetch-bin?uuid=${id}`
      )
      .then((res) => {
        if (res.data.data) {
          loadRazorPay()
          mixpanel.track("Subscription Page", { ...res.data.data })
          setState(res.data.data)
        } else {
          toast.error("Please make sure the link is valid")
          navigate("/")
        }
      })
      .catch((err) => {
        toast.error("Some error occured. Please make sure the link is valid.")
        navigate("/")
      })
  }, [id])

  return (
    <Grid container item xs={12} justifyContent="center">
      {getActivePaymentStep(paymentStatus)}
      <EnterpriseForm isMobile={isMobile} open={open} setOpen={setOpen} />
    </Grid>
  )
}

export default Subscription
