import React from "react";
import Lottie from "react-lottie";
function Animation({ animationData, height = 250, width = 250, style = {} }) {
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
    renderer: "svg",
  };
  return (
    <Lottie
      style={{ ...style }}
      isClickToPauseDisabled={true}
      options={defaultOptions}
      height={height}
      width={width}
      isStopped={false}
      isPaused={false}
    />
  );
}

export default Animation;
