import "./index.css"
import img1 from "./../../assets/images/Carousel/client1.png"
import img2 from "./../../assets/images/Carousel/client2.png"
import img3 from "./../../assets/images/Carousel/client3.png"
import img4 from "./../../assets/images/Carousel/client4.png"
import img5 from "./../../assets/images/Carousel/client5.png"
import img6 from "./../../assets/images/Carousel/client6.png"
import img7 from "./../../assets/images/Carousel/client7.png"
import img8 from "./../../assets/images/Carousel/client8.png"
import img9 from "./../../assets/images/Carousel/client9.png"
import img10 from "./../../assets/images/Carousel/client10.png"
import img11 from "./../../assets/images/Carousel/oh-logo.png"
import { Grid, Typography } from "@material-ui/core"
function Carousel({ isMobile = false }) {
  const images = [
    img1,
    img2,
    img3,
    img4,
    img5,
    img6,
    img7,
    img8,
    "https://storage.googleapis.com/website-paperplane/ic_logo.png",
    img9,
    img10,
    img11,
    img1,
    img2,
    img3,
    img4,
    img5,
    img6,
    img7,
    img8,
    "https://storage.googleapis.com/website-paperplane/ic_logo.png",
    img9,
    img11,
  ]
  return (
    <Grid item xs={12} style={{ margin: "1rem -8.5%" }}>
      <div class="slider">
        <div class="slide-track">
          {images.map((ele, index) => (
            <div class="slide">
              <img
                src={ele}
                height={(index === 8 || index === 20) ? "180" : (isMobile ? "180" : "220")}
                width={(index === 8 || index === 20) ? "140" : (isMobile ? "180" : "220")}
                alt=""
                style={{ objectFit: "contain", marginTop: (index === 8 || index === 20) ? "20px" : null }}
              />
            </div>
          ))}
        </div>
      </div>
    </Grid>
  )
}

export default Carousel
