import React, { useState, useEffect } from "react"
import mixpanel from "mixpanel-browser"
import {
  FormControlLabel,
  FormGroup,
  Switch,
  withStyles,
} from "@material-ui/core"
import { colors } from "../../colors"
const IOSSwitch = withStyles((theme) => ({
  root: {
    width: 42,
    height: 28,
    padding: 2,
  },
  switchBase: {
    padding: 1,
    "&$checked": {
      transform: "translateX(16px)",
      color: theme.palette.common.white,
      "& + $track": {
        background: colors.gradient,
        opacity: 1,
        border: "none",
      },
    },
    "&$focusVisible $thumb": {
      color: "#52d869",
      border: "6px solid #fff",
    },
  },
  thumb: {
    width: 24,
    height: 24,
  },
  track: {
    borderRadius: 26 / 2,
    border: `1px solid ${theme.palette.grey[400]}`,
    backgroundColor: theme.palette.grey[50],
    opacity: 1,
    transition: theme.transitions.create(["background-color", "border"]),
  },
  checked: {},
  focusVisible: {},
}))(({ classes, ...props }) => {
  return (
    <Switch
      focusVisibleClassName={classes.focusVisible}
      disableRipple
      classes={{
        root: classes.root,
        switchBase: classes.switchBase,
        thumb: classes.thumb,
        track: classes.track,
        checked: classes.checked,
      }}
      {...props}
    />
  )
})
export default function PricingSwitch({ plan, setPlan }) {
  useEffect(() => {
    if (mixpanel) mixpanel?.track("Quarterly Plan")
  })
  const handleChange = (event) => {
    if (event.target.checked) {
      if (mixpanel) mixpanel?.track("Annual Plan")
    } else {
      if (mixpanel) mixpanel?.track("Quarterly Plan")
    }
    setPlan(event.target.checked)
  }

  return (
    <FormGroup>
      <FormControlLabel
        style={{ margin: "0" }}
        control={<IOSSwitch checked={plan} onChange={handleChange} />}
        label=""
      />
    </FormGroup>
  )
}
