import {
  Dialog,
  DialogContent,
  Grid,
  Hidden,
  Typography,
} from "@material-ui/core"
import axios from "axios"
import mixpanel from "mixpanel-browser"
import React, { useState } from "react"
import { toast } from "react-toastify"
import GenericTextfield from "../Common/GenericTextfield"
import StartButton from "../Common/StartButton"
import enterpriseImage from "./../../assets/images/Pricing/Group 36918.png"

import { colors } from "../../colors"
function EnterpriseForm({ isMobile, open, setOpen }) {
  const styles = {
    number: {
      background: colors.purpleGradient,
      WebkitBackgroundClip: "text",
      WebkitTextFillColor: "transparent",
      backgroundClip: "text",
    },
    text: {
      fontSize: "12px",
    },
  }
  const [state, setState] = useState({})
  const enterpriseAdvantages = [
    { number: "25+", text: "Businesses trust Paperplane Clinic" },
    { number: "50%", text: "Increase OPD occupancy by 50%" },
    { number: "40%", text: "Increase patient retention by 40%" },
  ]
  const inputFields = [
    {
      label: "Enter your name",
      primaryKey: "name",
    },
    {
      label: "Enter your phone number",
      primaryKey: "phoneNo",
      validate: (value) => value.toString().length === 10,
    },

    {
      label: "Please specify your business type",
      primaryKey: "type",

      options: [
        "PolyClinic",
        "Hospital",
        "Individual Clinic",
        "Diagnostic Centre",
        "Pharmacy Chain",
        "IVF Centre",
        "Nursing Home",
      ],
      type: "select",
    },

    {
      label: "How many doctors does your business have?",
      primaryKey: "doctorNos",

      options: ["0-10", "10-25", "25-50", "50-100", "100+"],
      type: "select",
    },
    {
      label: "What is your daily patient inbound?",
      optional: true,
      primaryKey: "dailyPatients",

      showOptional: true,
      options: ["25-50", "50-100", "100-500", "500+"],
      type: "select",
    },
  ]
  const handleSubmit = (state) => {
    let flag = 1
    for (const field of inputFields) {
      if (field.validate && field.validate(state[field.primaryKey])) continue
      else if (!field.optional && !state[field.primaryKey]) {
        toast.error(field?.label)
        flag = 0
        break
      }
    }
    if (flag) {
      axios
        .post(
          `https://pplane-api-prod.paperplane.health/api/v1/doctor/enterprise-data`,
          state
        )
        .then((res) => {
          if (res.data.success) {
            mixpanel.track("Enterprise Request Submitted", { ...state })
            setOpen(false)
            setState({})
            toast.success("We'll reach out to you within 24 hours.")
          } else {
            toast.error("Some error occured")
          }
        })
        .catch((err) => toast.error("Some error occured"))
    }
  }

  return (
    <Dialog open={open} onClose={() => setOpen(false)} fullWidth maxWidth="xl">
      {/* <DialogTitle title="hello" l>Paperplane Enterprise</DialogTitle> */}
      <DialogContent
        style={{
          background:
            "linear-gradient(110.86deg, rgba(77, 99, 221, 0.2) 31.36%, rgba(34, 161, 245, 0.2) 100%)",
        }}
      >
        <Grid
          container
          xs={12}
          item
          style={{ alignItems: "center" }}
          // direction={!isMobile ? "initial" : "column-reverse"}
        >
          <Grid
            container
            md={6}
            xs={12}
            justifyContent="center"
            spacing={2}
            alignItems="center"
            style={{
              padding: "2rem",
              // background:
              //   "linear-gradient(110.86deg, rgba(77, 99, 221, 0.2) 31.36%, rgba(34, 161, 245, 0.2) 100%)",
            }}
          >
            <Grid item xs={12} style={{ textAlign: "center" }}>
              <Typography variant="h3">
                Book a demo for <br />
                <strong>Paperplane Enterprise</strong>
              </Typography>
            </Grid>
            {/* </Hidden> */}
            <Grid item xs={12} style={{ textAlign: "center" }}>
              <img
                src={enterpriseImage}
                style={{ width: isMobile ? "200px" : "300px" }}
                alt="Paperplane Enterprise"
              ></img>
            </Grid>
            <Grid container item xs={12} justifyContent="space-evenly">
              {enterpriseAdvantages.map((ele) => (
                <Grid item style={{ padding: "1rem" }} md={4}>
                  <Grid
                    item
                    style={{
                      background: "aliceblue",
                      padding: "1rem",
                      borderRadius: "18px",
                    }}
                  >
                    <Typography
                      variant="h1"
                      align="center"
                      style={styles.number}
                    >
                      {ele.number}
                    </Typography>
                    <Typography
                      style={{ marginTop: "1rem", ...styles.text }}
                      variant="body1"
                      align="center"
                    >
                      {ele?.text}
                    </Typography>
                  </Grid>
                </Grid>
              ))}
            </Grid>
          </Grid>
          <Grid
            container
            xs={12}
            md={6}
            item
            alignItems="flex-end"
            spacing={2}
            style={{
              padding: isMobile ? "1rem" : "2rem",
              borderRadius: "18px",
              background: "white",
            }}
          >
            {/* <Hidden mdUp>
              <Grid item xs={12} style={{ marginBottom: "2rem" }}>
                <Typography variant="h3" align="center">
                  Book a demo for <br />
                  <strong>Paperplane Enterprise</strong>
                </Typography>
              </Grid>
            </Hidden> */}
            {inputFields.map((field) => (
              <GenericTextfield
                {...field}
                state={state}
                setState={setState}
              ></GenericTextfield>
            ))}{" "}
            <Grid
              item
              xs={12}
              style={{
                marginTop: "2rem",
                textAlign: isMobile ? "center" : "left",
              }}
            >
              <StartButton
                label="Submit"
                onClick={() => handleSubmit(state)}
              ></StartButton>
              <StartButton
                label="Close"
                variant="outlined"
                style={{marginLeft: "10px"}}
                onClick={() => setOpen(false)}
              ></StartButton>
            </Grid>
          </Grid>
        </Grid>
      </DialogContent>
    </Dialog>
  )
}

export default EnterpriseForm
