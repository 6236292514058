import { Grid, IconButton, Typography } from "@material-ui/core"
import React from "react"
import { colors } from "../../colors"
import card1 from "./../../assets/images//Vision/CardV1.svg"
import card2 from "./../../assets/images//Vision/CardV2.svg"
import card3 from "./../../assets/images//Vision/CardV3.svg"
import card4 from "./../../assets/images//Vision/CardV4.svg"
import card5 from "./../../assets/images//Vision/CardV5.svg"
import groupPic from "./../../assets/images/Vision/WhatsApp Image 2022-05-19 at 9.48.44 AM.png"
import f1 from "./../../assets/images/Vision/Dhruv.svg"
import f2 from "./../../assets/images/Vision/Devansh.svg"
import f3 from "./../../assets/images/Vision/Aryan.svg"
import f4 from "./../../assets/images/Vision/Varun.svg"
import LinkedIn from "./../../assets/images/Footer/LinkedIn.png"

import { Helmet } from "react-helmet"

function Vision({ isMobile }) {
  const styles = {
    mobileHeaderImage: {
      objectFit: "contain",
      width: "275px",
      margin: "2rem 0",
      borderRadius: "4px",
    },
    headerImage: {
      objectFit: "contain",
      width: "600px",
      margin: "2rem 0",
      borderRadius: "12px",
      marginBottom: "1rem",
    },
    foundersImage: {
      objectFit: "contain",
      width: "200px",
      margin: "1rem",
    },
    mobileFoundersImage: {
      objectFit: "contain",
      width: "140px",
      margin: "1rem",
    },
  }
  const founders = [
    {
      img: f1,
      name: "Dhruv Upadhyay",
      position: "COO",
      linkedIn: "https://www.linkedin.com/in/intmanear/",
    },
    {
      img: f2,
      name: "Devansh Swaroop",
      position: "CEO",
      linkedIn: "https://www.linkedin.com/in/devansh-swarup-85436554/",
    },
    {
      img: f3,
      name: "Aryan Pandey",
      position: "CPO",
      linkedIn: "https://www.linkedin.com/in/aryan-pandey/",
    },
    {
      img: f4,
      name: "Varun Goel",
      position: "CTO",
      linkedIn: "https://www.linkedin.com/in/varun-goel-132988192/",
    },
  ]
  const cardArray = [
    { img: card1, text: "Obsession to details", background: "#e5e8ff" },
    { img: card2, text: "Curiousity", background: "#d9ffc7" },
    { img: card3, text: "Bias for Action", background: "#ffd9cd" },
    { img: card4, text: "Ownership", background: "#ffe8a1" },
    { img: card5, text: "Altruism", background: "#f4befc" },
  ]
  return (
    <Grid
      container
      justifyContent="center"
      alignItems="center"
      data-aos="fade-up"
    >
      <Helmet>
        <meta charSet="utf-8" />
        <title>
          {" "}
          Paperplane WhatsApp Clinic- Build your Digital Clinic on Whatsapp in
          less than 7 minutes | Vision
        </title>
      </Helmet>

      <Grid item xs={12} style={{ textAlign: "center" }}>
        <Typography
          variant="h1"
          style={{
            textDecorationLine: "underline",
            textDecorationColor: colors.primary,
          }}
        >
          Our Vision. <span style={{ color: colors.primary }}>Our Mission</span>
        </Typography>
      </Grid>
      <Grid item md={8}>
        <Typography style={{ marginTop: "2rem" }} align="center">
          At Paperplane we're building Digital Clinics on WhatsApp for Doctors
          and Hospital to start and scale their medical practice using
          everyone's favourite messaging app!
        </Typography>
      </Grid>
      <Grid item xs={12} style={{ textAlign: "center", margin: "1rem 0" }}>
        <img
          src={groupPic}
          style={isMobile ? styles.mobileHeaderImage : styles.headerImage}
          alt="grouoSvg"
        ></img>
      </Grid>
      <Grid item xs={12} style={{ textAlign: "center", marginTop: "2rem" }}>
        <Typography variant="h1" style={{ fontWeight: "700" }}>
          Our Core Values
        </Typography>
        <Typography variant="h5">
          The principles that guide our company strategy and our individual
          actions.
        </Typography>
      </Grid>
      <Grid
        container
        xs={12}
        justifyContent="center"
        style={{ marginTop: "1rem" }}
      >
        {cardArray.map((ele) => (
          <div
            style={{
              width: isMobile ? "100px" : "110px",
              height: "120px",
              margin: "1rem",
              justifyContent: "space-between",
              background: ele.background,
              borderRadius: "18px",
              display: "flex",
              flexDirection: "column",
              padding: "1rem",
            }}
          >
            <img
              src={ele.img}
              alt="card"
              style={{ objectFit: "100%", width: "30px" }}
            ></img>
            <Typography variant="body1">{ele.text}</Typography>
          </div>
        ))}
      </Grid>
      <Grid item xs={12} style={{ marginTop: "3rem" }}>
        <Typography variant="h1" align="center">
          Our Team
        </Typography>
      </Grid>
      <Grid
        item
        container
        xs={12}
        justifyContent="center"
        style={{ marginTop: "1rem" }}
      >
        {founders.map((ele) => (
          <div
            style={{
              width: "220px",
              boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)",
              height: isMobile ? "250px" : "350px",
              margin: "0.5rem",
              background: "#f3f3f3",
              justifyContent: "space-between",
              alignItems: "center",
              borderRadius: "18px",
              display: "flex",
              flexDirection: "column",
              padding: "1rem",
            }}
          >
            <img
              style={
                isMobile ? styles.mobileFoundersImage : styles.foundersImage
              }
              alt="founder"
              src={ele.img}
            ></img>
            <Typography variant="h5">{ele.name}</Typography>
            <Typography variant="h6" color="primary">
              {ele.position}
            </Typography>
            <div style={{}}>
              <IconButton
                color="primary"
                onClick={() => window.open(ele.linkedIn)}
              >
                <img
                  src={LinkedIn}
                  alt={ele.to}
                  style={{ color: colors.primary, width: "32px" }}
                ></img>
              </IconButton>
            </div>
          </div>
        ))}
      </Grid>
    </Grid>
  )
}

export default Vision
