import { Button, Grid, makeStyles, Typography } from "@material-ui/core"
import React from "react"
import { useNavigate } from "react-router"
import StartButton from "../Common/StartButton"

function AboutUs() {
  const navigate = useNavigate()
  return (
    <Grid
      container
      justifyContent="center"
      data-aos="fade-up"
      data-aos-delay={200}
      data-aos-once={true}
      style={{ margin: "2rem 0" }}
    >
      <Grid item xs={12}>
        <Typography variant="h1" color="initial" align="center">
          About Us
        </Typography>
      </Grid>
      <Grid item style={{ marginTop: "2rem" }} md={6} xs={10}>
        <Typography align="center" color="textSecondary" variant="h5">
          At Paperplane we're building Digital Clinics on WhatsApp for Doctors
          and Hospital to start and scale their medical practice using
          everyone's favourite messaging app!
        </Typography>
      </Grid>
      <Grid item xs={12} style={{ textAlign: "center", marginTop: "2rem" }}>
        <StartButton
          label="Know More"
          onClick={() => {
            navigate("./vision")
            window.scrollTo(0, 0)
          }}
        ></StartButton>
      </Grid>
    </Grid>
  )
}

export default AboutUs
