import { Button, Grid, TextField, Typography } from "@material-ui/core";
import { Email, Phone } from "@material-ui/icons";
import React, { useState } from "react";
import { colors } from "../../colors";
import ContactInput from "../Common/ContactInput";

function ContactUs({ isMobile = false }) {
  return (
    <Grid
      container
      justifyContent="center"
      style={{ marginTop: "2rem" }}
      data-aos="fade-up"
      data-aos-delay={200}
      data-aos-once={true}
    >
      <Grid item xs={12} md={11}>
        <div
          style={{
            padding: "2rem",
            background: "#FFFFFF",
            boxShadow: "0px 4px 20px rgba(0, 0, 0, 0.1)",
            borderRadius: "18px",
          }}
        >
          <Grid
            container
            spacing={2}
            justifyContent={isMobile ? "center" : "center"}
          >
            <Grid
              item
              md={6}
              xs={12}
              container
              direction="column"
              alignItems={isMobile ? "center" : "flex-start"}
            >
              <Typography
                align="inherit"
                variant="h1"
                style={{ marginBottom: "0.5rem" }}
              >
                Contact Us
              </Typography>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "inherit",

                  margin: "0.25rem 0",
                }}
              >
                <Email
                  color="primary"
                  fontSize="small"
                  style={{ paddingRight: "0.5rem" }}
                />

                <a
                  href="mailto:support@paperplanetech.co"
                  style={{ textDecoration: "none" }}
                >
                  <Typography color="primary">
                    support@paperplanetech.co
                  </Typography>{" "}
                </a>
              </div>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "inherit",
                  margin: "0.25rem 0",
                }}
              >
                <Phone
                  color="primary"
                  fontSize="small"
                  style={{ paddingRight: "0.5rem" }}
                />

                <a href="tel:+91 6387662004" style={{ textDecoration: "none" }}>
                  <Typography color="primary">+91 6387662004</Typography>{" "}
                </a>
              </div>
            </Grid>
            <Grid
              container
              justifyContent={isMobile ? "center" : "flex-end"}
              item
              md={5}
              xs={12}
            >
              <ContactInput isMobile={isMobile}></ContactInput>
            </Grid>
          </Grid>
        </div>
      </Grid>
    </Grid>
  );
}

export default ContactUs;
