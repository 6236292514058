import { Divider, Grid, Hidden, Typography } from "@material-ui/core"
import React from "react"
import { colors } from "../../colors"
import StartButton from "../Common/StartButton"
function Body({
  isMobile = false,
  body = { features: [] },
  styles,
  buttonProps = { label: "Start Now", onClick: () => {} },
}) {
  return (
    <Grid container style={{ margin: "2rem 0" }}>
      <Grid
        container
        item
        xs={12}
        data-aos="fade-in"
        data-aos-once={true}
        data-aos-delay={400}
        justifyContent="center"
        spacing={3}
        alignItems="center"
        style={{ marginTop: "2rem" }}
      >
        <Grid item>
          <Typography
            variant="h5"
            style={{ fontWeight: "500", fontFamily: "Montserrat" }}
          >
            <span style={{ fontWeight: "600" }}>Paperplane</span>
            <span
              style={{
                padding: "0.5rem",
                background: colors.gradient,
                color: "#fff",
                marginLeft: "0.5rem",
                borderRadius: "11px",
              }}
            >
              {body?.text}
            </span>
            <br />
            Right for {isMobile ? "👇" : "👉"}
          </Typography>
          <div style={{ textAlign: isMobile ? "center" : "left" }}>
            <StartButton
              {...buttonProps}
              style={{ margin: "2rem 0rem" }}
            ></StartButton>
          </div>
        </Grid>
        {body?.heading?.map((ele, idx) => (
          <div key={idx}>
            <div
              style={{
                width: isMobile ? "150px" : "200px",
                height: isMobile ? "180px" : "220px",
                margin: "1.6rem 0.4rem",
                background: "#fff",
                boxShadow: "0px 4px 20px rgba(0, 0, 0, 0.18)",
                borderRadius: "18px",
                display: "flex",
                flexDirection: "column",
                alginItems: "center",
              }}
            >
              <div
                style={{
                  background: "rgba(161, 176, 255, 0.5)",
                  textAlign: "center",
                  borderRadius: "18px 18px 0 0  ",
                  padding: "1rem",
                }}
              >
                <img
                  src={ele.img}
                  alt={ele.text}
                  style={{
                    objectFit: "contain",
                    width: isMobile ? "80px" : "120px",
                    height: isMobile ? "80px" : "120px",
                  }}
                ></img>
              </div>
              <div
                style={{
                  padding: "0.5rem",
                  height: "inherit",
                  alignItems: "center",
                  justifyContent: "center",
                  display: "flex",
                }}
              >
                <Typography
                  variant="h6"
                  align="center"
                  style={{ fontWeight: "700" }}
                >
                  {ele.text}
                </Typography>
              </div>
            </div>
          </div>
        ))}
      </Grid>
      <Hidden mdUp>
        <Grid item xs={12}>
          <Divider />
        </Grid>
      </Hidden>
      {body?.features?.map((ele, idx) => (
        <Grid
          container
          key={idx}
          spacing={2}
          alignItems="center"
          style={{ margin: "3rem 0" }}
        >
          {isMobile || idx % 2 !== 0 ? (
            <Grid
              item
              xs={12}
              md={6}
              style={{ textAlign: isMobile ? "center" : "left" }}
              data-aos={isMobile ? "fade-up" : "fade-right"}
              data-aos-once={true}
              data-aos-delay={isMobile ? 100 : 200}
            >
              <img
                src={ele.img}
                style={
                  ele.imgStyle
                    ? ele.imgStyle
                    : isMobile
                    ? styles.mobileHeaderImage
                    : styles.headerImage
                }
                alt={ele.heading}
              ></img>
            </Grid>
          ) : null}
          <Grid
            item
            xs={12}
            data-aos={
              isMobile ? "fade-up" : idx % 2 !== 0 ? "fade-left" : "fade-right"
            }
            md={6}
            data-aos-delay={isMobile ? 100 : 200}
            data-aos-once={true}
            style={{ textAlign: isMobile ? "center" : "left" }}
          >
            <Typography
              variant="h1"
              style={{ marginBottom: "2rem", fontWeight: "700" }}
            >
              {ele.heading}
            </Typography>
            <Grid item style={{ textAlign: "left" }}>
              <Typography variant="h5" color="textSecondary">
                {ele.content}
              </Typography>
            </Grid>
            <StartButton {...buttonProps} style={{ marginTop: "2rem" }} />
          </Grid>
          {!isMobile && idx % 2 === 0 ? (
            <Grid
              item
              xs={12}
              md={6}
              style={{ textAlign: "right" }}
              data-aos="fade-left"
              data-aos-delay={isMobile ? 100 : 200}
              data-aos-once={true}
            >
              <img
                src={ele.img}
                style={
                  ele.imgStyle
                    ? ele.imgStyle
                    : isMobile
                    ? styles.mobileHeaderImage
                    : styles.headerImage
                }
                alt={ele.heading}
              ></img>
            </Grid>
          ) : null}
        </Grid>
      ))}
    </Grid>
  )
}

export default Body
