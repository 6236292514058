import {
  Button,
  CircularProgress,
  Grid,
  TextField,
  Typography,
} from "@material-ui/core";
import React, { useState } from "react";
import { colors } from "../../../colors";
import axios from "axios";
import { toast } from "react-toastify";
function ContactInput({ isMobile = false }) {
  const [number, setNumber] = useState("");
  const [loading, setLoading] = useState(false);
  const regex = new RegExp("(^[0-9]+$|^$)");
  const submitPhone = () => {
    if (number.toString().length !== 10)
      toast.error("Phone number must be of 10 digits.");
    else {
      setLoading(true);
      axios
        .post(
          `https://pplane-api-prod.paperplane.health/api/v1/ai/send-email?email=${number}`
        )
        .then((res) => {
          console.log(res);
          setLoading(false);
          setNumber("");
          toast.success("We'll get back to you shortly!");
        })
        .catch((err) => {
          setLoading(false);
          console.log(err);
        });
    }
  };
  return (
    <Grid item xs={12} container>
      <Typography variant="h6" color="textSecondary">
        For any queries enter your Phone Number and we will get back to you
      </Typography>
      <TextField
        style={{ marginTop: "1.5rem" }}
        inputProps={{ maxLength: "10" }}
        variant="outlined"
        value={number}
        onChange={(e) => {
          if (regex.test(e.target.value)) {
            setNumber(e.target.value);
          }
        }}
        placeholder="Your phone number"
        fullWidth
        type="text"
        InputProps={{
          style: { borderRadius: "33px" },
          endAdornment: !isMobile ? (
            <div>
              {loading ? (
                <CircularProgress color="primary"></CircularProgress>
              ) : (
                <Typography
                  onClick={() => submitPhone(number)}
                  style={{
                    cursor: "pointer",
                    color: "#fff",
                    background:
                      "linear-gradient(128.8deg, #4D63DD 28.61%, #22A1F5 99.07%)",

                    padding: "0.75rem 1rem",
                    borderRadius: "29px",
                  }}
                  variant="body2"
                >
                  Connect
                </Typography>
              )}
            </div>
          ) : null,
        }}
      ></TextField>
      {!isMobile ? null : (
        <Grid item xs={12} style={{ textAlign: "center", marginTop: "2rem" }}>
          {loading ? (
            <CircularProgress color="primary"></CircularProgress>
          ) : (
            <Button
              variant="contained"
              color="primary"
              onClick={() => submitPhone(number)}
            >
              Connect
            </Button>
          )}
        </Grid>
      )}
    </Grid>
  );
}

export default ContactInput;
