import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Grid,
  Typography,
} from "@material-ui/core"
import { ArrowDownward, ArrowDropDown, ExpandMore } from "@material-ui/icons"
import React, { useState } from "react"
const defaultQuestions = [
  {
    question: " How are we different from other Healthcare providers?",
    answer:
      "Paperplane is a healthcare platform with an integrated EMR solution completely built on Top of Whatsapp that enables doctors to connect with their patients on their favorite messaging app.",
  },
  {
    question: "How will I be getting new patients via Paperplane?",
    answer:
      "Paperplane offers the doctor, the ability to establish their own brand and attract new patients via a variety of channels, including their website, organic search results on Google Search (Via Google My Business), advertisements on a variety of social media platforms, along with WhatsApp Marketing",
  },
  {
    question: "Does Paperplane support WhatsApp Broadcasting? ",
    answer:
      "Yes, Paperplane offers a complete marketing suite for doctors, which enables them to distribute informative posters on a variety of social media platforms, run advertisements via their own portfolio website, and even broadcast their own branded template messages and posters.",
  },
  {
    question: "Is Paperplane for Individual Doctors or Hospitals?",
    answer:
      "We have two product offerings, Paperplane Lite which is for individual practitioners and second is Paperplane Enterprise which is a complete whitelabelled solution for Hospitals and Polyclinics. ",
  },
  {
    question: "How secure is Paperplane?",
    answer:
      "Our product is HIPPA Compliant as well as ISO Certified so all the data of the client is stored in encrypted format on cloud which is completely secure. To read our privacy policy please visit https://www.paperplane.health/privacyPolicy ",
  },
]
function FAQ({
  isMobile,
  questions = defaultQuestions,
  title = "Frequently asked questions",
}) {
  const [open, setOpen] = useState(-1)

  return (
    <>
      <Grid
        container
        direction="column"
        alignItems="center"
        style={{ margin: "3rem 0" }}
      >
        <Typography variant="h1" align="center" style={{ margin: "1rem 0" }}>
          {title}
        </Typography>

        {questions.map((q, idx) => (
          <Grid
            style={{ width: "100%" }}
            item
            key={idx}
            data-aos={isMobile ? "fade-up" : "fade-right"}
            data-aos-once={true}
            data-aos-delay={50}
          >
            <Accordion
              expanded={idx === open}
              onChange={() => {
                if (idx === open) setOpen(-1)
                else setOpen(idx)
              }}
              style={{
                background: "#FFFFFF",
                border: "1px solid #E3E3E3",
                padding: "1rem",

                margin: "0.5rem",
                boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)",
                borderRadius: "14px",
              }}
            >
              <AccordionSummary
                expandIcon={
                  <ArrowDropDown color={idx === open ? "primary" : "inherit"} />
                }
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <Typography color={idx === open ? "primary" : "inherit"}>
                  {q.question}
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography color="textSecondary">{q.answer}</Typography>
              </AccordionDetails>
            </Accordion>
          </Grid>
        ))}
      </Grid>
    </>
  )
}

export default FAQ
