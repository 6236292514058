import {
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  Grid,
  Radio,
  RadioGroup,
  Typography,
} from "@material-ui/core"
import React, { useEffect, useState } from "react"
import mixpanel from "mixpanel-browser"

import img1 from "./../../assets/images/Demo/Group 36980 (1).png"
// import img6 from "./../../assets/images/Demo/";
import img5 from "./../../assets/images/Demo/Group 36579 (1).png"
import img4 from "./../../assets/images/Demo/Group 36880.png"
import img3 from "./../../assets/images/Demo/Group 36881.png"
import img2 from "./../../assets/images/Demo/Group 36977.png"
import pimg1 from "./../../assets/images/Demo/Group 36985.png"
import pimg3 from "./../../assets/images/Demo/Group 36980.png"
import pimg2 from "./../../assets/images/Demo/Group 36984.png"
import pimg4 from "./../../assets/images/Demo/Group 36981.png"
import defa4ltImage from "./../../assets/images/ClinicBody/Group 36579.png"
import StartButton from "../Common/StartButton"
import ProductTour from "../ProductTour"
// import StepMaker from "../ProductTour copy";
function Demo({ isMobile }) {
  const styles = {
    typography: { color: "white" },
  }
  const [run, setRun] = useState(false)
  const [restart, setRestart] = useState(false)
  const [viewAs, setViewAs] = useState("doctor")
  const [open, setOpen] = useState(false)
  const handleChange = (e) => {
    console.log("eter", e.target.value)
    setViewAs(e.target.value)
  }
  const [step, setStep] = useState(0)
  const getCurrentStepImageDoctor = (step) => {
    switch (step) {
      case 0:
        return img1

      case 1:
        return img2

      case 2:
        return img3

      case 3:
        return img4
      case 4:
        return img5

      default:
        break
    }
  }
  const getCurrentStepImagePatient = (step) => {
    switch (step) {
      case 0:
        return pimg1

      case 1:
        return pimg2

      case 2:
        return pimg3

      case 3:
        return pimg4

      default:
        break
    }
  }
  const onStepUpdate = (data) => {
    console.log("step", data)
    if (data.action === "next") {
      console.log("data", data)
      mixpanel.track("Next in Demo Clicked", { curr: data?.index })
    }
    if (data.action === "reset") {
      setRun(false)
      setRestart(false)
    }
    if (data.action === "close" || data.action === "stop") {
      setOpen(false)
      setRun(false)
      setRestart(false)
      setStep(0)
    }
    setStep(data.index)
  }
  // useEffect(() => {
  //   if (open) {
  //     const query = document?.getElementsByClassName("react-joyride__beacon");
  //     if (query) query[0]?.click();
  //     // console.log("poka", query[0].click());
  //   }
  // }, [open]);

  return (
    <Grid
      container
      item
      xs={12}
      style={{
        background: "linear-gradient(128.8deg, #4D63DD 28.61%, #22A1F5 99.07%)",
        textAlign: "center",
        margin: isMobile ? "3rem 0" : "5rem 0 5rem 0",
        padding: "2rem",
        borderRadius: "18px",
      }}
    >
      <Grid item xs={12}>
        <Typography variant="h1" style={styles.typography} align="center">
          Watch Paperplane in action 🎬
        </Typography>
      </Grid>{" "}
      <Grid container item xs={12} style={{ margin: "2rem 0" }} spacing={2}>
        <Grid item xs={12} md={12} container justifyContent="center">
          <Grid
            item
            xs={12}
            justifyContent="center"
            spacing={2}
            direction="column"
            alignItems="center"
          >
            <Grid item xs={12}>
              <Typography
                variant="h2"
                style={{ ...styles.typography, margin: "1rem 0" }}
              >
                View Demo as
              </Typography>
            </Grid>
            <Grid item xs={12} style={{ justifyContent: "center" }} container>
              <RadioGroup
                aria-label="gender"
                name="gender1"
                style={{ ...styles.typography, marginBottom: "1rem" }}
                value={viewAs}
                row
                onChange={(e) => setViewAs(e.target.value)}
              >
                <FormControlLabel
                  value={"doctor"}
                  control={<Radio color="secondary" />}
                  label="Doctor"
                />
                <FormControlLabel
                  value={"patient"}
                  control={<Radio color="secondary" />}
                  label="Patient"
                />
              </RadioGroup>
            </Grid>
            <Grid item xs={12}>
              <Button
                variant="contained"
                style={{ color: "white", background: "rgba(0, 0, 0, 0.4)" }}
                onClick={() => {
                  setOpen(true)
                  setRun(true)
                  setRestart(true)
                  mixpanel.track("Demo Launched", { viewedAs: viewAs })
                }}
              >
                Launch Demo
              </Button>
            </Grid>
          </Grid>
        </Grid>
        {open ? (
          <Dialog
            open={open}
            // fullScreen={true}
            PaperProps={{
              elevation: 0,
              style: {
                background: "border-box",
                border: "0px",
              },
            }}
            maxWidth="xl"
            // fullWidth={true}
            onClose={() => {
              setOpen(false)

              setRestart(false)
              setRun(false)
            }}
            style={{ zIndex: "99" }}
          >
            {/* <DialogTitle>Hello</DialogTitle> */}
            <DialogContent>
              <ProductTour
                run={run}
                steps={
                  viewAs === "doctor"
                    ? [
                        {
                          target: ".productTour1",
                          content: (
                            <div>
                              <Typography>
                                {" "}
                                Get appointments through your WhatsApp Clinic.
                              </Typography>
                            </div>
                          ),

                          disableBeacon: true,
                          placement: isMobile ? "bottom" : "left",
                        },
                        {
                          target: ".productTour1",
                          content: (
                            <Typography>
                              {" "}
                              Write E-prescriptions, take self-notes and set
                              follow ups.
                            </Typography>
                          ),
                          placement: isMobile ? "bottom" : "left",
                        },
                        {
                          target: ".productTour1",
                          content: (
                            <Typography>
                              Send E-Rx, medicine and follow-up reminders on
                              WhatsApp
                            </Typography>
                          ),
                          placement: isMobile ? "bottom" : "left",
                        },
                        {
                          target: ".productTour1",
                          content: (
                            <Typography>
                              Send messages using the Paperplane app while the
                              patient can respond on WhatsApp
                            </Typography>
                          ),
                          placement: isMobile ? "bottom" : "left",
                        },
                        {
                          target: ".productTour1",
                          content: (
                            <Typography>
                              Go digital, build your brand using personalised
                              websites and marketing content.
                            </Typography>
                          ),
                          placement: isMobile ? "bottom" : "left",
                        },
                      ]
                    : [
                        {
                          target: ".productTour2",
                          content: (
                            <div>
                              <Typography>
                                {" "}
                                Patients interact with your multi-lingual
                                WhatsApp assistant.
                              </Typography>
                            </div>
                          ),
                          disableBeacon: true,

                          placement: isMobile ? "bottom" : "left",
                        },
                        {
                          target: ".productTour2",
                          content: (
                            <Typography>
                              {" "}
                              Patients can choose locations, time-slots and book
                              appointments on WhatsApp
                            </Typography>
                          ),
                          placement: isMobile ? "bottom" : "left",
                        },
                        {
                          target: ".productTour2",
                          content: (
                            <Typography>
                              Instant confirmation and payments over WhatsApp
                            </Typography>
                          ),
                          placement: isMobile ? "bottom" : "left",
                        },
                        {
                          target: ".productTour2",
                          content: (
                            <Typography>
                              Patients receive E-Rx and medicine and follow up
                              reminders over WhatsApp
                            </Typography>
                          ),
                          placement: isMobile ? "bottom" : "left",
                        },
                      ]
                }
                onStepUpdate={onStepUpdate}
                restart={restart}
                isMobile={isMobile}
              />
              {viewAs === "doctor" && (
                <img
                  src={getCurrentStepImageDoctor(step)}
                  className="productTour1"
                  style={{
                    width: isMobile ? "250px" : "300px",
                    // height: isMobile ? "200px" : "inherit",
                    objectFit: "contain",
                  }}
                ></img>
              )}
              {viewAs === "patient" && (
                <img
                  src={getCurrentStepImagePatient(step)}
                  className="productTour2"
                  style={{
                    width: isMobile ? "250px" : "300px",
                    // height: isMobile ? "200px" : "inherit",
                    objectFit: "contain",
                  }}
                ></img>
              )}
            </DialogContent>
          </Dialog>
        ) : null}
        <Grid item xs={12} md={8} style={{ marginTop: isMobile ? "3rem" : 0 }}>
          {/* {viewAs === "doctor" && (
            <img
              src={getCurrentStepImage(step)}
              className="productTour1"
              style={{
                width: isMobile ? "270px" : "400px",

                objectFit: "contain",
              }}
            ></img>
          )}
          {viewAs === "patient" && (
            <img
              src={getCurrentStepImage(step)}
              className="productTour1"
              style={{
                width: isMobile ? "270px" : "350px",

                objectFit: "contain",
              }}
            ></img>
          )} */}
        </Grid>
      </Grid>
    </Grid>
  )
}

export default Demo
