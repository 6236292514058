import {
  Button,
  Dialog,
  DialogContent,
  Divider,
  Drawer,
  Grid,
  Hidden,
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  makeStyles,
  SwipeableDrawer,
  Typography,
  withWidth,
} from "@material-ui/core"
import { Shop } from "@material-ui/icons"
import paperplaneLogo from "./../../assets/images/Logos/Group 36999.png"
import { useLocation, useNavigate } from "react-router"
import { Menu } from "@material-ui/icons"
import React, { useState } from "react"
import { colors } from "../../colors/index"
import { Link, NavLink } from "react-router-dom"
import mixpanel from "mixpanel-browser"
const useStyles = makeStyles((theme) => ({
  parentGrid: {
    filter: "drop-shadow(0px 2px 2px rgba(0, 0, 0, 0.04))",
    background: "#fff",
    position: "sticky",
    top: "3rem",
    zIndex: "999",

    [theme.breakpoints.down("sm")]: {
      position: "fixed",
      padding: "3% 8%",
      zIndex: "97",
      top: "3.5rem",
      backgroundColor: "#FFF",
    },

    [theme.breakpoints.up("md")]: {
      padding: "1% 8%",
    },
    [theme.breakpoints.up("lg")]: {},
  },
  doctorDetails: {
    [theme.breakpoints.down("sm")]: {
      justifyContent: "center",
      textAlign: "center",
    },
  },
  headerImage: {
    [theme.breakpoints.down("sm")]: {
      width: "300px",
    },
  },
}))
const Tag = ({ style }) => {
  return (
    <Typography
      style={{
        backgroundColor: "red",
        position: "absolute",
        padding: "0 0.5rem",
        borderRadius: "18px",
        right: "-1rem",
        top: "-1rem",
        fontSize: "11px",
        color: "white",
        width: "fit-content",
        ...style,
      }}
    >
      New
    </Typography>
  )
}
function NavBar({ data, width, isMobile = false }) {
  const [menuOpen, setMenuOpen] = useState(false)
  const navigate = useNavigate()
  const classes = useStyles()
  const navBarArray = [
    {
      label: "Home",
      route: "/",
      style: { color: colors.green, cursor: "pointer" },
      to: "/",
      onClick: () => {
        navigate("/")
        window.scrollTo(0, 0)
      },
    },
    // {
    //   label: "Buy Now Pay Later",
    //   route: "/bnpl",
    //   tag: true,
    //   style: { color: colors.green, cursor: "pointer" },
    //   to: "/",
    //   onClick: () => {
    //     // mixpanel.track("BNPL from navbar clicked")
    //     // window.open("https://www.youtube.com/channel/UCCIs3vb1mC15PrdPQBwgdjQ")
    //     navigate("/bnpl")
    //     window.scrollTo(0, 0)
    //   },
    // },
    {
      label: "Videos",
      route: "/videos",
      // tag: true,
      style: { color: colors.green, cursor: "pointer" },
      to: "/",
      onClick: () => {
        mixpanel.track("Videos from navbar clicked")
        window.open("https://www.youtube.com/channel/UCCIs3vb1mC15PrdPQBwgdjQ")
      },
    },
    // {
    //   label: "Video",
    //   style: { color: colors.green, cursor: "pointer" },
    //   onClick: () => {
    //     navigate("/");
    //     window.scrollTo(0, 0);
    //   },
    // },
    // {
    //   label: "Features",
    //   style: { color: colors.green, cursor: "pointer" },
    //   onClick: () => {
    //     navigate("/");
    //     window.scrollTo(0, 0);
    //   },
    // },
    //  {
    //   label: "Pricing",
    //  route: "/pricing",
    //   to: "/",
    //
    //   style: { color: colors.green, cursor: "pointer" },
    //     onClick: () => {
    //       navigate("/pricing")
    //     window.scrollTo(0, 0)
    //   },
    //  },
    {
      label: "Blogs",
      route: "/blogs",
      to: "/",

      style: { color: colors.green, cursor: "pointer" },
      onClick: () => {
        navigate("/blogs")
        window.scrollTo(0, 0)
      },
      tag: false,
    },
    {
      label: "ABHA",
      route: "/abdm",
      to: "/",

      style: { color: colors.green, cursor: "pointer" },
      onClick: () => {
        navigate("/abdm")
        window.scrollTo(0, 0)
      },
      tag: true,
    },

    {
      label: "About Us",
      route: "/vision",
      style: { color: colors.green, cursor: "pointer" },
      onClick: () => {
        navigate("/vision")
        window.scrollTo(0, 0)
      },
    },
    // {
    //   label: "Contact Us",
    //   style: { color: colors.green, cursor: "pointer" },
    //   onClick: () => {
    //     navigate("/");
    //     window.scrollTo(0, 0);
    //   },
    // },
  ]
  return (
    <Grid container alignItems="center">
      <Grid
        item
        xs={12}
        container
        className={classes.parentGrid}
        style={{
          flexWrap: "nowrap",
          // justifyContent: "space-between",
        }}
      >
        <Grid
          item
          container
          md={4}
          style={{
            cursor: "pointer",
            flexWrap: "nowrap",
            verticalAlign: "middle",
          }}
          onClick={() => navigate("/")}
        >
          <img
            alt="Clinic Logo"
            src={paperplaneLogo}
            style={{
              objectFit: "contain",
              width: isMobile ? "170px" : "225px",
              paddingLeft: "1rem",
            }}
          ></img>
        </Grid>
        <Hidden smDown>
          <Grid
            container
            item
            alignItems="center"
            justifyContent="space-between"
            spacing={1}
            style={{
              flexWrap: "nowrap",
              marginLeft: "-10%",
              whiteSpace: "nowrap",
              padding: "0 3.5rem",
            }}
          >
            <Grid container justifyContent="space-evenly" item xs={9}>
              {navBarArray.map((ele, idx) => (
                <Grid item key={idx} style={{ position: "relative" }}>
                  <NavLink
                    to={ele.route}
                    onClick={() => {
                      if (ele.onClick) ele?.onClick()
                      window?.scrollTo(0, 0)
                    }}
                    style={({ isActive }) => ({
                      fontFamily: "Montserrat",
                      color: isActive ? colors.green : "#656565",
                      textDecoration: "none",
                      fontSize: "18px",
                      textDecorationLine: "none",
                      fontWeight: isActive ? "1000" : "300",
                      marginLeft: 10
                    })}
                  >
                    {ele.label}
                  </NavLink>
                  {ele.tag ? <Tag /> : null}
                </Grid>
              ))}
            </Grid>
            <Grid item container justifyContent="space-evenly" xs={3}>
              <Grid item>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={() => {
                    mixpanel.track("Download App Clicked")

                    window.open(
                      "https://play.google.com/store/apps/details?id=paperplane.health.app&hl=en_GB&gl=IN"
                    )
                  }}
                  style={{ fontSize: "14px", padding: "0.2rem 1rem" }}
                >
                  Download App
                </Button>
              </Grid>
              <Grid item>
                <Button
                  variant="outlined"
                  color="primary"
                  onClick={() => {
                    mixpanel.track("Login Clicked")

                    window.open("https://app.paperplane.health/auth/login")
                  }}
                  style={{ fontSize: "14px", padding: "5% 50%" }}
                >
                  Login
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Hidden>
        <Hidden mdUp>
          <Grid item>
            <IconButton size="large" onClick={() => setMenuOpen(true)}>
              {" "}
              <Menu />
            </IconButton>
            <Drawer
              anchor="bottom"
              open={menuOpen}
              onClose={() => setMenuOpen(false)}
            >
              <div
                style={{
                  width: "auto",
                  anchor: "bottom",
                  backgroundColor: "#fff",
                }}
              >
                <List onClick={() => setMenuOpen(false)}>
                  {[...navBarArray].map((ele, index) => (
                    <ListItem button key={ele.label}>
                      <ListItemText style={{ position: "relative" }}>
                        <NavLink
                          to={ele.route}
                          onClick={() => window?.scrollTo(0, 0)}
                          style={({ isActive }) => ({
                            fontFamily: "Montserrat",
                            textDecoration: "none",
                            fontSize: "18px",
                            display: "block",
                            color: isActive ? colors.green : "#656565",

                            textDecorationLine: "none",
                            fontWeight: isActive ? "900" : "500",
                          })}
                        >
                          {ele.label}
                        </NavLink>
                        {/* {ele.tag ? (
                          <Tag style={{ left: "3.2rem", top: "-0 0.1rem" }} />
                        ) : null} */}
                      </ListItemText>
                    </ListItem>
                  ))}
                  <ListItem>
                    <ListItemText
                      onClick={() => {
                        mixpanel.track("Login Clicked")

                        window.open("https://app.paperplane.health/auth/login")
                      }}
                      primary="Login"
                      primaryTypographyProps={{
                        style: {
                          fontFamily: "Montserrat",
                          textDecoration: "none",
                          fontSize: "18px",
                          textDecorationLine: "none",
                          color: "#656565",
                          fontWeight: "500",
                        },
                      }}
                    ></ListItemText>
                  </ListItem>
                  <ListItem>
                    <ListItemText
                      onClick={() => {
                        mixpanel.track("Download App Clicked")

                        window.open(
                          "https://play.google.com/store/apps/details?id=paperplane.health.app&hl=en_GB&gl=IN"
                        )
                      }}
                      primary="Download App"
                      primaryTypographyProps={{
                        style: {
                          fontFamily: "Montserrat",
                          textDecoration: "none",
                          fontSize: "18px",
                          textDecorationLine: "none",
                          color: "#656565",
                          fontWeight: "500",
                        },
                      }}
                    ></ListItemText>
                  </ListItem>
                </List>
              </div>
            </Drawer>
          </Grid>
        </Hidden>
      </Grid>
    </Grid>
  )
}

export default withWidth()(NavBar)
