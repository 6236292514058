import { Grid, IconButton, Typography } from "@material-ui/core";
import React from "react";
import economic from "./../../assets/images/AsSeenOn/20190308065725-ENT-India-Logo-Black.svg";
import eIndia from "./../../assets/images/AsSeenOn/et-logo.png";
import yourStory from "./../../assets/images/AsSeenOn/yourStory.svg";
function AsSeenOn({ isMobile }) {
  const style = {
    img: {
      width: "200px",
      height: isMobile ? "125px" : "150px",
      objectFit: "contain",
      cursor: "pointer",
    },
  };
  const array = [
    {
      img: yourStory,
      link: "https://yourstory.com/2022/04/bengaluru-healthtech-startup-paperplane-digital-clinics-whatsapp/amp",
    },
    {
      img: eIndia,
      link: "https://economictimes.indiatimes.com/tech/funding/digital-clinic-paperplane-raises-rs-3-crore-from-cornerstone-venture-partners-others/articleshow/90519313.cms",
    },
    { img: economic, link: "https://www.entrepreneur.com/article/423344" },
  ];
  return (
    <Grid
      container
      item
      xs={12}
      style={{
        textAlign: "center",
        margin: isMobile ? "3rem 0" : "5rem 0 0 0",
      }}
    >
      <Grid item xs={12}>
        <Typography variant="h1" color="initial" align="center">
          As seen on! 📰
        </Typography>
      </Grid>
      <Grid
        container
        item
        xs={12}
        justifyContent="center"
        alignItems="center"
        style={{
          margin: "1.5rem 0",
          //   padding: "1rem",
          //   background: "#FFFFFF",
          //   boxShadow: "0px 4px 20px rgba(0, 0, 0, 0.1)",
          //   borderRadius: "18px",
        }}
      >
        {array.map((ele) => (
          <Grid
            item
            // xs={12}
            style={{
              //   background: "#f3f3f3",
              padding: isMobile ? "2.5rem" : "2rem",
              margin: "2rem",
              borderRadius: "20px",
              position: "relative",
            }}
            className="logo"
          >
            <img
              src={ele.img}
              onClick={() => window.open(ele?.link)}
              style={style.img}
              alt={ele?.link}
            />
            <IconButton
              style={{ position: "absolute", top: "0.5rem", right: "0.5rem" }}
              color="primary"
              size="small"
              onClick={() => window.open(ele?.link)}
            >
              <i class="fa-solid fa-arrow-up-right-from-square"></i>{" "}
            </IconButton>
          </Grid>
        ))}
      </Grid>
    </Grid>
  );
}

export default AsSeenOn;
