import { Grid, makeStyles, Typography } from "@material-ui/core";
import React from "react";
import aws from "./../../assets/images/BackedBy/AWS startup logo 1.png";
import hunderdX from "./../../assets/images/BackedBy/100x logo 1.png";
import mongoDb from "./../../assets/images/BackedBy/MongoDB startup logo 1.png";
import venture from "./../../assets/images/BackedBy/Group 36630.png";
import cornerStone from "./../../assets/images/BackedBy/cornerstone logo 1.png";
const useStyles = makeStyles((theme) => ({
  parentGrid: {
    justifyContent: "center",
    marginBottom: "1rem",
    [theme.breakpoints.down("sm")]: {
      padding: "1rem",
    },

    [theme.breakpoints.up("md")]: {
      padding: "2rem",
    },
    [theme.breakpoints.up("lg")]: {},
  },
  headerImage: {
    [theme.breakpoints.down("sm")]: {
      width: "150px",
    },
  },
}));
function BackedBy({ isMobile }) {
  const styles = {
    aoeImage: {
      objectFit: "contain",
      cursor: "pointer",
      width: "200px",
      padding: "1rem",
      marginTop: "1rem",
    },
    aoeMobileImage: {
      cursor: "pointer",
marginTop:'1.5rem',
      padding: "1rem",
      objectSize: "contain",
      width: "200px",
    },
  };
  const classes = useStyles();
  const backedBy = [
    { img: cornerStone, to: "https://www.csvpfund.com/" },
    { img: hunderdX, to: "https://www.100x.vc/" },
    { img: venture, to: "https://www.letsventure.com/" },
    { img: aws },
    { img: mongoDb },
  ];
  return (
    <Grid
      container
      className={classes.parentGrid}
      data-aos="fade-up"
      data-aos-delay={200}
      data-aos-once={true}
    >
      <Grid item xs={12} style={{ margin: "2rem 0 1rem 0" }}>
        <Typography variant="h1" align="center">
          Backed by the best! 🤟🏽
        </Typography>
      </Grid>
      <Grid
        container
        item
        justifyContent="center"
        style={{
          padding: "1rem",
          // background: "#FFFFFF",
          // boxShadow: "0px 4px 20px rgba(0, 0, 0, 0.1)",
          // borderRadius: "18px",
        }}
      >
        {backedBy?.map((ele, index) => (
          <Grid
            item
            key={index}
            md={4}
            sm={6}
            xs={12}
            container
            style={{
              textAlign: "center",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <img
              src={ele.img}
              onClick={() => (ele.to ? window.open(ele.to) : null)}
              alt="logo"
              className="logo"
              style={isMobile ? styles.aoeMobileImage : styles.aoeImage}
            ></img>
          </Grid>
        ))}
      </Grid>
    </Grid>
  );
}

export default BackedBy;
