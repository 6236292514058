import { Grid, Hidden, StylesProvider, Typography } from "@material-ui/core"
import React from "react"
import { colors } from "../../colors"
import marketingImg from "./../../assets/images/ClinicBody/Group 36579.png"
import whatsapp from "./../../assets/images/Features/whatsapp (1).png"
import documents from "./../../assets/images/Features/documents.png"
import Erx from "./../../assets/images/Features/E Rx.png"
import other from "./../../assets/images/Features/other.png"
import connected from "./../../assets/images/Features/Patient connected.png"
import website from "./../../assets/images/Features/website icon.png"
import www from "./../../assets/images/Features/worldwide brand.png"
import web from "./../../assets/images/Features/web.png"
import rupee from "./../../assets/images/Features/rupee.png"
function Features({ isMobile }) {
  const features = [
    {
      title: "Your own WhatsApp Assistant",
      image: whatsapp,
      description:
        "Automatically get appointments and stay in touch with your patients using WhatsApp",
    },
    {
      title: "All in 1 Practice Management Tool",
      image: other,
      description:
        "Digitize your practice within minutes. One platform for staff, doctors and admin to manage your clinic.",
    },
    {
      title: "Get Free website",
      image: website,
      description:
        "Grow your digital presence. Get your own personalised website in less than 5 minutes.",
    },
    {
      title: "No more paperwork",
      image: documents,
      description:
        "Get all the information on your fingertips - Patient history, Appointment details, payments and much more",
    },
    {
      title: "Custom Domain",
      image: web,
      description:
        "Make your profile unique by getting your own custom domain name that is easy to remember",
    },
    {
      title: "Online payments",
      image: rupee,
      description:
        "Get more sales, by offering seamless and easy payment options on your WhatsApp Clinic.",
    },
    {
      title: "Create a Global Brand",
      image: www,
      description:
        "Create & Grow your personal brand digitally. Expand your practice globally under your own brand.",
    },
    {
      title: "Stay connected to patients",
      image: connected,
      description:
        "Send medicine reminders, Follow-up reminders, medical advice and documents on WhatsApp",
    },
    {
      title: "Beautiful & Quick Digital Rx",
      image: Erx,
      description:
        "Generate and share AI Powered digital Prescriptions via your phone or laptop in <30 seconds",
    },
  ]
  return (
    <Grid
      item
      container
      xs={12}
      style={{ margin: isMobile ? "3rem 0" : "5rem 0" }}
    >
      <Grid item xs={12}>
        <Typography align="center" variant="h1">
          Features & Services
        </Typography>
      </Grid>
      <Grid
        item
        xs={12}
        container
        style={{ marginTop: "2rem" }}
        justifyContent="center"
      >
        {features.map((ele) => (
          <Grid item xs={12} md={4} style={{ padding: "0.5rem" }} container>
            <Grid
              item
              xs={12}
              style={{
                // boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px",
                // borderRadius: "12px",
                padding: "1.1rem",
                textAlign: isMobile ? "center" : "left",
              }}
            >
              <img
                src={ele.image}
                style={{ width: "60px", height: "60px", objectFit: "contain" }}
              />

              <Typography
                variant="h6"
                style={{ marginTop: "1rem", fontWeight: "600", color: "grey" }}
              >
                {ele.title}
              </Typography>
              <Typography variant="body1" style={{ marginTop: "1rem" }}>
                {ele.description}
              </Typography>
            </Grid>
          </Grid>
        ))}
      </Grid>
    </Grid>
  )
}

export default Features
