import React from "react"
import { Grid, Typography, TextField, MenuItem } from "@material-ui/core"
const defaultHandleChange = (value, state, setState, primaryKey) => {
  const temp = { ...state }
  temp[primaryKey] = value
  setState({ ...temp })
}
function GenericTextfield({
  xs = 12,
  md = 6,
  label = "",
  style = {},
  ...props
}) {
  const getActiveTextfield = (type) => {
    const {
      placeholder = "",
      state = {},
      primaryKey = "primaryKey",
      value = state[primaryKey],
      setState = () => {},
      handleChange = defaultHandleChange,
      inputProps = {},
    } = props
    if (primaryKey === "name") {
      console.log(props)
    }
    switch (type) {
      case "select":
        return (
          <TextField
            fullWidth
            variant="outlined"
            placeholder={placeholder}
            select
            value={value}
            {...inputProps}
            onChange={(e) => {
              handleChange(e.target.value, state, setState, primaryKey)
            }}
          >
            {props?.options.map((ele) => (
              <MenuItem value={ele.value ? ele.value : ele}>
                {ele.label ? ele.label : ele}
              </MenuItem>
            ))}
          </TextField>
        )

      default:
        return (
          <TextField
            fullWidth
            variant="outlined"
            placeholder={placeholder}
            type={type}
            value={value}
            {...inputProps}
            helperText={
              inputProps?.helperText ? (
                <div style={{ marginLeft: "-0.1rem" }}>
                  {inputProps.helperText}
                </div>
              ) : null
            }
            onChange={(e) => {
              handleChange(e.target.value, state, setState, primaryKey)
            }}
          ></TextField>
        )
    }
  }
  return (
    <Grid
      item
      container
      md={md}
      xs={xs}
      style={{ overflowY: "visible", marginTop: "0.5rem", ...style }}
    >
      <Grid item xs={12} style={{ marginBottom: "0.5rem" }}>
        <Typography variant="body1" style={{ fontWeight: "400" }}>
          {label}{" "}
          {props?.optional ? (
            <span style={{ fontSize: "0.75rem" }}>(Optional)</span>
          ) : null}
        </Typography>
      </Grid>
      <Grid item xs={12}>
        {getActiveTextfield(props.type)}
      </Grid>
    </Grid>
  )
}

export default GenericTextfield
