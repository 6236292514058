import React from "react";
function Video() {
  return (
    <div>
      <video width="480px" height="400px" autoPlay={true} controls></video>
    </div>
  );
}

export default Video;
