import { Typography } from "@material-ui/core";
import React from "react";
import Joyride from "react-joyride";
import { colors } from "../../colors";

function ProductTour({
  steps = [],
  run,
  onStepUpdate,
  restart,
  stepIndex,
  isMobile,
}) {
  console.log("step", run);
  return (
    <Joyride
      steps={steps}
      stepIndex={stepIndex}
      continuous={true}
      showProgress={true}
      run={run}
      floaterProps={{ style: { options: { color: "black" } } }}
      disableScrolling={true}
      styles={{
        options: {
          backgroundColor: "#0b0d12",
          arrowColor: "rgba(0, 0, 0, 0.7)",
          textColor: "white",
          primaryColor: colors.green,
          width: isMobile ? "270px" : "350px",
          beaconSize: 40,
          overlayColor: "rgba(0, 0, 0, 0.7)",
        },
      }}
      scrollToFirstStep={restart}
      callback={onStepUpdate}
    />
  );
}

export default ProductTour;
