import logo from "./logo.svg"
import "./App.css"
import { Navigate, Route, Routes } from "react-router"
import Home from "./views/Home"
import Pricing from "./views/Pricing"
import Navbar from "./Components/Navbar"
import { Button, Grid, Typography, withWidth } from "@material-ui/core"
import Footer from "./Components/Footer"
import Refund from "./Components/Refund"
import Tnc from "./Components/TnC"
import PrivacyPolicy from "./Components/PrivacyPolicy"
import Vision from "./Components/Vision"
import { ToastContainer } from "react-toastify"
import { useEffect } from "react"
import Aos from "aos"
import "aos/dist/aos.css"
import "react-toastify/dist/ReactToastify.css"
import { initFbPIxel } from "./fbPixel"
import mixpanel from "mixpanel-browser"
import { colors } from "./colors"
import Blogs from "./views/Blogs"
import GenerciBlog from "./Components/GenericBlog"
import thumbnail1 from "./assets/images/Blogs/thumbnail1.png"
import thumbnail2 from "./assets/images/Blogs/thumbnail2.png"
import Subscription from "./views/Subscription/index"
import Abdm from "./views/Abdm"
import Bnpl from "./views/BNPL"
export const blogList = [
  {
    youtubeLink: "https://www.youtube.com/embed/PkPsiPWzxb0",
    route: "how-to-set-up-your-digital-clinic",
    blogImage: thumbnail1,
    blogTitle: "How to set up your digital clinic in 7 minutes?",
    blogBody: (
      <div>
        <p>
          <strong>Set up a digital clinic in 7 Minutes</strong>
        </p>
        <p>
          The world is taking more and more steps toward a digital and to keep
          yourself level in the changing world, one must change with the
          changing times. An online presence and clinic setup are the need of
          the hour, to not only keep yourself abreast of the changing times but
          also to reap the benefits from it. With an online presence and clinic,
          you can not double but quadruple your engagement, taking it from a
          couple of hundred to a few thousand in a couple of months.
        </p>
        <p>
          <strong>Why is it important to go digital</strong>
        </p>
        <p>
          Let us tell you four very important reasons for you to take your
          clinic digital-
        </p>
        <ol>
          <li>
            Patients and future customers are turning towards digital means to
            reach out to medical practitioners. (Platforms like- Google Seach,
            Whatsapp, Instagram)
          </li>
          <li>
            Constant access to an easy and transparent talking channel between
            the patient and the doctor, making it easier for both of them to
            reach out in their time of need.
          </li>
          <li>The set-up cost is low but the brand awareness is high.</li>
          <li>
            The digital process is Super Easy, leading to patients retaining
            information better and repeat consultations because the ease of the
            process.
          </li>
        </ol>
        <p>
          <strong>How to go Digital?</strong>
        </p>
        <p>To go digital you have to follow four steps-</p>
        <p>a) Build a website.</p>
        <p>b) Start a google business account</p>
        <p>c) Social media account over various platforms.</p>
        <p>d) Build a Growth strategy.</p>
        <p>
          You can go digital through the means of an agency which will take upto
          3-4 hours of your week or you can do it yourself which will take upto
          3-4 hours a day.
        </p>
        <p>
          You must be thinking this sounds like a lot of work, and you are
          right, it is. But what if we told you that you can do all of this
          through just one app?
        </p>
        <p>
          You can use Paperplane Clinic App, to set up your own Digital clinic
          on Whatsapp, and also get a free website with your own domain and
          clinic name.
        </p>
        <p>
          Paperplane will help with all the different types of patients that you
          get. There are three types of patients generally -
        </p>
        <p>
          1) <strong>Patients who walk into the clinic</strong> - The Paperplane
          app will help you to make new consultations and add in the data and
          details of the patient, quickly and efficiently.
        </p>
        <p>
          2) <strong>Patients calling at the desk for Enquiries</strong> - The
          Paperplane app will send you a direct WhatsApp assistant link where
          they clarify their queries.
        </p>
        <p>
          3) <strong>Patients Trying to contact you on WhatsApp</strong> - You
          can send them the direct WhatsApp assistant link and by adding this
          link to your Facebook and Instagram bios, your patients will always
          where and how to reach out to you.
        </p>
        <p>And for this to happen, all you have to do is-</p>
        <ol>
          <li>
            <p>
              Download the Paperplane clinic app from Apple or Google Play
              Store.
            </p>
          </li>
          <li>
            <p>Open up the application and fill in the details.</p>
          </li>
        </ol>
        <p>
          And that&apos;s it, you are done. That&apos;s all you have to do to
          get your digital clinic up and running.
          <br /> Watch the video attached where Dr Manan takes you &nbsp;through
          the entire process discussed above.
        </p>
      </div>
    ),
  },
  {
    route: "how-to-set-up-your-google-business-profile",
    youtubeLink: "https://www.youtube.com/embed/P-wKWRnfJ7c",
    blogImage: thumbnail2,

    blogTitle:
      "How to set up your Google Business Profile (GMB) for your clinic  ?",
    blogBody: (
      <div>
        <p>
          <strong>
            Set up your Google business profile and start growth marketing with
            your personalized clinic website
          </strong>
        </p>
        <p>
          Let us go through a scenario together, let&rsquo;s say a patient has
          terrible back pain and wants to immediately book an appointment with a
          good doctor.
        </p>
        <p>
          Now the question arises: how does a patient decide which doctor would
          they want to consult?
        </p>
        <p>
          And in this digitally-driven world, the answer is simple enough
          -patients search for doctors via google first. Wouldn&apos;t you like
          to be included in these google results and not be a part of it but
          also consult them?
        </p>
        <p>
          Now imagine having your website which can convert leads into patients
          by allowing them to not only look through your profile but also
          allowing them to quickly book appointments in just one clink, along
          with every update like prescriptions, follow-ups, and payments over
          Whatsapp! Sounds good right? Here is how you go about it-
        </p>
        <p>
          <strong>
            How to set up an online presence to drive patients to your clinic
          </strong>
        </p>
        <p>The solution is to have a google business account.</p>
        <p>
          For example, when someone searches for a doctor on Google search, the
          first result is a list of relevant clinics near their current
          location, along with the names, locations, and websites.
        </p>
        <p>
          And not only this, the other advantages of a good business account
          includes-
        </p>
        <ol>
          <li>
            <p>Creating your ads to get more patient leads.</p>
          </li>
          <li>
            <p>
              Directing patients to your clinic with one click booking through
              Whatsapp.
            </p>
          </li>
          <li>
            <p>
              Attracting more patient leads by showing reviews and ratings on
              your google business page
            </p>
          </li>
        </ol>
        <p>
          <strong>
            How to create a Google business profile and reach out to patients
            from pan India!
          </strong>
        </p>
        <ol>
          <li>
            <p>
              Just download Google&apos;s &lsquo;My Business&rsquo; application
              and open it.
            </p>
          </li>
          <li>
            <p>
              Choose the email id with which you would want to be associated
              with your clinic.
            </p>
          </li>
          <li>
            <p>
              Enter the details like your clinic&rsquo;s name and your business
              category.
            </p>
          </li>
          <li>
            <p>
              Select &lsquo;yes&rsquo; if you&rsquo;re looking for offline
              patients in your clinic.
            </p>
          </li>
          <li>
            <p>
              And next, enter the complete address of your clinic. Enter the
              state, and next, choose the appropriate location after that.
            </p>
          </li>
          <li>
            <p>
              Answer the questions as per your preferences and the services your
              clinic will be providing.
            </p>
          </li>
          <li>
            <p>
              Enter the contact number you want your clinic to be associated
              with, and enter your website URL.
            </p>
          </li>
          <li>
            <p>Select yes and move forward.</p>
          </li>
          <li>
            <p>
              Choose how to verify your clinic and location (And for that you
              just need to enter your name).
            </p>
          </li>
        </ol>
        <p>
          And now that your google business profile is ready, use
          Paperplane&rsquo;s Weekly content templates to share and grow your
          Google business page and get more patient leads!
          <br /> Watch the video attached where Dr Manan takes you through the
          entire process discussed above.
        </p>
      </div>
    ),
  },
]

function App({ width }) {
  useEffect(() => {
    Aos.init()

    initFbPIxel()
  }, [])
  const isMobile = width === "xs" || width === "sm" ? true : false
  const isTab = width === "md" ? true : false

  return (
    <div>
      <Grid
        item
        xs={12}
        container
        style={{
          background: colors.blue,

          maxHeight: isMobile ? "70px" : "56px",
          padding: "0.5rem 2rem",
          zIndex: isMobile ? "96" : "999",
          position: "fixed",
          top: "0",
        }}
        alignItems="center"
        spacing={2}
      >
        <Grid item xs={12}>
          <Typography variant="h6" style={{ color: "white" }} align="center">
            💡 Hear it from our customers, on how to use Paperplane.{" "}
            <span
              style={{
                color: "#fff",
                textDecoration: "underline",
                cursor: "pointer",
              }}
              onClick={() => {
                mixpanel.track("Top Banner Clicked", {
                  url: "https://youtu.be/PkPsiPWzxb0",
                })
                window.open("https://youtu.be/PkPsiPWzxb0")
              }}
            >
              Learn more&gt;&gt;
            </span>
          </Typography>
        </Grid>
      </Grid>
      <Navbar isMobile={isMobile} />
      <div style={{ margin: "10rem 0 3rem 0", padding: isMobile ? "1% 5%" : "3% 8%" }}>
        <Routes>
          <Route
            element={
              <Home isMobile={isMobile} isTab={isTab} blogList={blogList} />
            }
            path="/"
          ></Route>
          {blogList?.map((ele) => {
            return (
              <Route
                path={`/blogs/${ele.route}`}
                element={
                  <GenerciBlog
                    {...ele}
                    isMobile={isMobile}
                    blogList={blogList}
                  ></GenerciBlog>
                }
              ></Route>
            )
          })}
          <Route
            element={<Blogs isMobile={isMobile} blogList={blogList} />}
            path="/blogs"
          />
          <Route
            element={<Pricing isMobile={isMobile} />}
            path="/paperplane-pricing"
          ></Route>
          <Route element={<Abdm isMobile={isMobile} />} path="/abdm" />
          <Route element={<Bnpl isMobile={isMobile} />} path="/bnpl" />
          <Route
            element={<Vision isMobile={isMobile} />}
            path="/vision"
          ></Route>
          {/* <Route element={<Refund />} path="/cancellationPolicy"></Route> */}
          <Route element={<Tnc />} path="/termsAndConditions"></Route>
          <Route element={<PrivacyPolicy />} path="/privacyPolicy"></Route>
          <Route
            element={<Subscription isMobile={isMobile} />}
            path="/payment/:id"
          ></Route>
          <Route path="*" element={<Navigate to="/" replace />} />{" "}
        </Routes>
      </div>
      <Footer isMobile={isMobile} />
      <ToastContainer
        position="bottom-center"
        autoClose={3000}
        limit={1}
        hideProgressBar={true}
      />
    </div>
  )
}

export default withWidth()(App)
