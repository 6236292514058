import React, { useState } from "react"
import "./index.css"
import { Grid, Typography } from "@material-ui/core"
import { colors } from "../../../colors"
import CountUp from "react-countup"
import bg1 from "./../../../assets/images/Switch/bg1.png"
import bg2 from "./../../../assets/images/Switch/bg2.png"
import bg3 from "./../../../assets/images/Switch/bg3.png"
import imgLogo from "./../../../assets/images/Logos/Group 36999.png"
import AsSeenOn from "../../AsSeenOn"
import Features from "../../Features"
function Switch({ checked, setChecked, isMobile }) {
  const styles = {
    box: {
      minWidth: isMobile ? "200px" : "275px",
      maxWidth: isMobile ? "200px" : "275px",
      margin: "1rem",
      height: isMobile ? "200px" : "275px",
      boxShadow:
        "rgba(17, 17, 26, 0.05) 0px 1px 0px, rgba(17, 17, 26, 0.1) 0px 0px 8px",
      borderRadius: "18px 18px 0px 0px",

      backgroundSize: "cover",
      backgroundRepeat: "no-repeat",
      justifyContent: "center",
    },
    counter: {
      fontWeight: "600",
      fontSize: isMobile ? "40px" : "55px",
    },
  }
  return (
    <div
      data-aos="fade-up"
      data-aos-delay={200}
      data-aos-once={true}
      style={{
        display: "flex",

        justifyContent: "center",
        flexDirection: "column",
        alignItems: "center",
        marginTop: "2rem",
      }}
    >
      <Grid
        container
        xs={12}
        spacing={4}
        alignItems="center"
        justifyContent="space-around"
        style={{ textAlign: "center", margin: "2rem 0" }}
      >
        <Grid
          container
          item
          direction="column"
          justifyContent="center"
          style={{
            ...styles.box,
            backgroundImage: `url(${bg1})`,
          }}
        >
          <Typography
            style={{
              ...styles.counter,
            }}
          >
            <CountUp
              end={600000}
              suffix="+"
              separator=","
              delay={2}
              useEasing={true}
              className="counterSpan"
            ></CountUp>
          </Typography>
          <Typography variant="h3">Patients </Typography>
        </Grid>
        <Grid
          container
          item
          direction="column"
          justifyContent="center"
          style={{
            ...styles.box,
            backgroundImage: `url(${bg2})`,
          }}
        >
          <Typography
            style={{
              ...styles.counter,
            }}
          >
            <CountUp
              end={3000}
              suffix="+"
              separator=","
              delay={2}
              useEasing={true}
              className="counterSpan"
            ></CountUp>
          </Typography>

          <Typography variant="h3"> Doctors </Typography>
        </Grid>
        <Grid
          container
          item
          direction="column"
          style={{
            ...styles.box,
            backgroundImage: `url(${bg3})`,
          }}
        >
          <Typography
            style={{
              // color: "#1ABFC7",
              ...styles.counter,
            }}
          >
            <CountUp
              end={100000}
              suffix="+"
              delay={2}
              separator=","
              useEasing={true}
              className="counterSpan"
            ></CountUp>
          </Typography>
          <Typography variant="h3"> Appointments </Typography>
        </Grid>
      </Grid>

      <div
        style={{
          margin: "1.5rem 0",
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
        }}
      >
        <img
          src={imgLogo}
          alt="logo"
          style={{ width: isMobile ? "200px" : "200px" }}
        />
        {/* <Typography
          variant="h2"
          style={{
            fontWeight: "600",
            fontFamily: "Montserrat",
            marginLeft: "0.5rem",
          }}
        >
          <span style={{ fontWeight: "600" }}>
            Paper<span style={{ color: colors.primary }}>plane</span>{" "}
          </span>{" "}
          for
        </Typography> */}
      </div>
      <div className="switch-button">
        <input
          className="switch-button-checkbox"
          type="checkbox"
          value={checked}
          onChange={(e) => {
            setChecked(e.target.checked)
          }}
        ></input>
        <label className="switch-button-label" htmlFor="switch">
          <span className="switch-button-label-span"> Clinics </span>
        </label>
      </div>
    </div>
  )
}

export default Switch
