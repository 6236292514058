import { Button, Grid, Typography } from "@material-ui/core"
import React, { useEffect } from "react"
import fallbackImage from "./../../assets/images/Hero/hero-image.svg"
import guideLogo from "./../../assets/images/Blogs/Group 36906.png"
import { useLocation } from "react-router"
import StartButton from "../Common/StartButton"
import mixpanel from "mixpanel-browser"
function GenerciBlog({
  blogTitle = "Generic Blog",
  blogImage = fallbackImage,
  blogBody = <div></div>,
  youtubeLink = null,
  isMobile = false,
  blogList = [],
}) {
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])
  const location = useLocation()
  const articleStructuredData = {
    "@context": "https://schema.org",
    "@type": "Article",
    headline: blogTitle,
    image: blogImage,
    datePublished: new Date("2022-06006T09:25:01.340Z"),
    author: {
      "@type": "Organization",
      name: "Paperplane Communications Pvt. Ltd.",
      url: "https://paperplanetech.co",
    },
  }
  console.log(location, "778 ")
  return (
    <Grid container item xs={12} justifyContent="center">
      <script type="application/ld+json">
        {JSON.stringify(articleStructuredData)}
      </script>{" "}
      <Grid
        container
        item
        alignItems="center"
        xs={12}
        style={{
          background: "#EFF1F7",
          borderRadius: "1rem",
          padding: "1.5rem 3rem",
          width: "100%",
        }}
      >
        <Grid item xs={12} md={6} container justifyContent="space-between">
          <Grid item xs={12}>
            <Typography variant="body1">
              <img
                src={guideLogo}
                alt="guide logo"
                style={{ width: "20px", marginRight: "0.5rem" }}
              ></img>
              Guide
            </Typography>
          </Grid>
          <Grid item xs={12}></Grid>
          <Typography variant="h1">{blogTitle}</Typography>
        </Grid>

        <Grid
          item
          xs={12}
          md={6}
          style={{ textAlign: "center", marginTop: isMobile ? "3rem" : "0" }}
        >
          <iframe
            width={isMobile ? "250" : "560"}
            height={"315"}
            style={{ borderRadius: "23px" }}
            src={youtubeLink}
            title="YouTube video player"
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowfullscreen
          ></iframe>
        </Grid>
      </Grid>
      <Grid item xs={12} md={8} justifyContent="center">
        <Typography variant="h5" style={{ marginTop: "5rem" }}>
          {" "}
          {blogBody}
        </Typography>
      </Grid>
      <Grid item xs={12} style={{ textAlign: "center", margin: "2rem 0" }}>
        <StartButton
          label="Start Now"
          to="https://website.paperplane.clinic"
        />
        <Typography
          style={{
            marginTop: "0.5rem",
            marginLeft: isMobile ? "0.7rem" : "1.4rem",
          }}
        >
          Takes less than 60s ⚡
        </Typography>
      </Grid>
      <Grid item xs={12} container style={{ marginTop: "3rem" }}>
        <Grid item xs={12} style={{ marginBottom: "2rem" }}>
          <Typography variant="h4" style={{ fontWeight: "500" }}>
            Suggested Blogs:
          </Typography>{" "}
        </Grid>

        {blogList.map((ele, idx) => {
          if (location.pathname.includes(ele.route)) return null
          if (isMobile && idx > 2) return null
          else
            return (
              <Grid
                item
                xs={12}
                container
                md={3}
                style={{
                  boxShadow:
                    "rgba(0, 0, 0, 0.05) 0px 6px 24px 0px, rgba(0, 0, 0, 0.08) 0px 0px 0px 1px",
                  padding: "2rem",
                  margin: "0.5rem",
                  borderRadius: "18px",
                }}
              >
                <Grid item xs={12} style={{ marginBottom: "1rem" }}>
                  <Typography variant="body1" color="textSecondary">
                    <img
                      src={guideLogo}
                      alt="guide logo"
                      style={{ width: "20px", marginRight: "0.5rem" }}
                    ></img>
                    Guide
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <Typography variant="body1">{ele.blogTitle}</Typography>
                </Grid>
                <Grid item xs={12} style={{ marginTop: "1rem" }}>
                  <Button
                    color="secondary"
                    href={ele.route}
                    variant="outlined"
                    size="small"
                    onClick={() => {
                      mixpanel.track(`${ele.blogTitle} Clicked`, {
                        from: "suggested",
                      })
                    }}
                  >
                    Read
                  </Button>
                </Grid>
              </Grid>
            )
        })}
      </Grid>
    </Grid>
  )
}

export default GenerciBlog
