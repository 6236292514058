import { Grid, Typography } from "@material-ui/core"
import React from "react"
import { colors } from "../../colors"
import paperplaneLogo from "./../../assets/images/Logos/Group 36999.png"

function GrowthNumbers({ isMobile = false }) {
  const styles = {
    growth: {
      borderRadius: "18px",
      // padding: isMobile ? "2rem" : "3rem",
      textAlign: "center",
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      columnGap: "1rem",
    },
    span: {
      background: colors.gradient,
      color: "white",
      padding: "0.3rem",
      borderRadius: "4px",
    },
    mobileSpan: {
      background: colors.gradient,
      WebkitBackgroundClip: "text",
      WebkitTextFillColor: "transparent",
      backgroundClip: "text",
    },

    numbers: {
      fontSize: isMobile ? "40px" : "60px",
      background: colors.purpleGradient,
      WebkitBackgroundClip: "text",
      WebkitTextFillColor: "transparent",
      backgroundClip: "text",
    },
  }
  return (
    <Grid
      item
      container
      xs={12}
      style={{
        margin: "4rem 0",
      }}
      alignItems=""
    >
      <Grid
        item
        xs={12}
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Typography
          align="center"
          variant="h1"
          style={{ verticalAlign: "center" }}
        >
          {" "}
          Boost your{" "}
          <span style={isMobile ? styles.mobileSpan : styles.span}>
            personal brand, patient retention & revenue{" "}
          </span>{" "}
        </Typography>
      </Grid>
      <Grid
        container
        item
        xs={12}
        spacing={3}
        alignItems="center"
        justifyContent="center"
        style={{ marginTop: "2rem" }}
      >
        <Grid item md={3} xs={12} style={{ padding: "2rem" }}>
          <div style={{ ...styles.growth }}>
            <Typography
              variant="h1"
              color="primary"
              style={{ ...styles.numbers }}
            >
              8/10
            </Typography>
            <br />
            <Typography variant="h6">
              patients feel more connected to clinics they can message.
            </Typography>
          </div>
        </Grid>
        <Grid item style={{ padding: "2rem" }} md={3} xs={12}>
          <div style={{ ...styles.growth }}>
            <Typography
              variant="h1"
              color="primary"
              style={{ ...styles.numbers }}
            >
              65%
            </Typography>{" "}
            <br />
            <Typography variant="h6">
              prefer messaging on WhatsApp over email or phone calls
            </Typography>
          </div>
        </Grid>
        <Grid item style={{ padding: "2rem" }} md={3} xs={12}>
          <div style={{ ...styles.growth }}>
            <Typography
              variant="h1"
              color="primary"
              style={{ ...styles.numbers }}
            >
              25%{" "}
            </Typography>{" "}
            <br />
            <Typography variant="h6">
              increase in repeat consultations over WhatsApp
            </Typography>
          </div>
        </Grid>
      </Grid>
    </Grid>
  )
}

export default GrowthNumbers
