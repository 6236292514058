import { Grid, Typography, withWidth } from "@material-ui/core"
import React, { useState } from "react"
import AboutUs from "../../Components/AboutUs"
import BackedBy from "../../Components/BackedBy"
import Body from "../../Components/Body"
import Switch from "../../Components/Common/Switch"
import ContactUs from "../../Components/ContactUs"
import FAQ from "../../Components/FAQ"
import Hero from "../../Components/Hero"
import Testimonials from "../../Components/Testimonials"
import Video from "../../Components/Video"
import Carousel from "../../Components/Carousel"

import img1 from "./../../assets/images/ClinicBody/Group 36880.png"
import img6 from "./../../assets/images/ClinicBody/Group 36592.png"
import img2 from "./../../assets/images/ClinicBody/Group 36571.svg"
import img3 from "./../../assets/images/ClinicBody/Group 36579.png"
import img4 from "./../../assets/images/ClinicBody/Mockup 1 (1).svg"
import img5 from "./../../assets/images/ClinicBody/Vid call 1.svg"
import individual from "./../../assets/images/ClinicBody/_2556293678288.png"
import smallClinic from "./../../assets/images/ClinicBody/polyclinic-2 1.png"
import { colors } from "../../colors"
import Eimg1 from "./../../assets/images/EnterpriseBody/Group 36845.png"
import Eimg2 from "./../../assets/images/EnterpriseBody/Frame 3836.png"
import Eimg3 from "./../../assets/images/EnterpriseBody/Group 36857 (1).png"
import Eimg4 from "./../../assets/images/EnterpriseBody/Group 36868.png"
import Eimg5 from "./../../assets/images/EnterpriseBody/Group 36859.png"
import hospital from "./../../assets/images/EnterpriseBody/hospital-1 1.png"
import multiple from "./../../assets/images/EnterpriseBody/_2556408270704.png"
import pharmacy from "./../../assets/images/EnterpriseBody/Group 36839.png"
import GetApp from "../../Components/GetApp"
import Helmet from "react-helmet"
import AsSeenOn from "../../Components/AsSeenOn"
import GrowthNumbers from "./../../Components/GrowthNumbers/index"
import Demo from "../../Components/Demo"
import Blogs from "../Blogs"
import EnterpriseForm from "../../Components/EnterpriseForm"
import Features from "../../Components/Features"
import { AbhaHeroSection } from "../Abdm"
import { BnplHeroSection } from "../BNPL"
function Home({ isMobile = false, isTab = false, blogList = [] }) {
  const [open, setOpen] = useState(false)
  const styles = {
    ul: {
      display: "flex",
      flexDirection: "column",
      rowGap: "12px",
      paddingInlineStart: "1.5rem",
    },
    mobileHeaderImage: {
      objectFit: "contain",
      width: "95%",
    },
    headerImage: {
      objectFit: "contain",
      width: "90%",
    },
    mobileSpan: {
      background: colors.gradient,
      WebkitBackgroundClip: "text",
      WebkitTextFillColor: "transparent",
      backgroundClip: "text",
    },
    span: {
      background: colors.gradient,
      color: "white",
      padding: "0.3rem",
      borderRadius: "4px",
    },
  }

  const EnterpriseBody = {
    text: "Enterprise",
    heading: [
      { img: hospital, text: "Hospital" },
      { img: smallClinic, text: "PolyClinic" },
      { img: multiple, text: "Pharmacy" },
    ],
    features: [
      {
        img: Eimg4,
        heading: (
          <>
            Provide seamless patient experience &
            <span
              style={
                isMobile
                  ? styles.mobileSpan
                  : { ...styles.span, marginLeft: "0.5rem" }
              }
            >
              {" "}
              improve retention
            </span>{" "}
          </>
        ),
        content: (
          <ul style={{ ...styles.ul }}>
            <li>
              Send E-Rx along with
              <span style={{ color: colors.blue, margin: "0 0.3rem" }}>
                medicine & follow-up
              </span>
              reminders
            </li>
            <li>
              Collect
              <span style={{ color: colors.blue, margin: "0 0.3rem" }}>
                medical data
              </span>
            </li>
            <li>
              Receive
              <span style={{ color: colors.blue, margin: "0 0.3rem" }}>
                online payments
              </span>
            </li>
          </ul>
        ),
      },
      {
        img: Eimg1,
        heading: (
          <>
            <span style={isMobile ? styles.mobileSpan : styles.span}>
              {" "}
              Go paperless
            </span>{" "}
            and optimise your services further!
          </>
        ),
        content: (
          <ul style={{ ...styles.ul }}>
            <li>
              Generate branded & clear
              <span style={{ color: colors.blue, margin: "0 0.3rem" }}>
                prescriptions within seconds
              </span>
              on WhatsApp
            </li>
            <li>
              Receive
              <span style={{ color: colors.blue, margin: "0 0.3rem" }}>
                online payments
              </span>
            </li>
          </ul>
        ),
      },
      {
        img: Eimg5,
        heading: (
          <>
            <span style={isMobile ? styles.mobileSpan : styles.span}>
              {" "}
              Boost sales
            </span>{" "}
            for both your virtual and offline services
          </>
        ),
        content: (
          <ul style={{ ...styles.ul }}>
            <li>
              <span style={{ color: colors.blue, margin: "0 0.3rem" }}>
                Regional language
              </span>
              support
            </li>
            <li>
              Accept
              <span style={{ color: colors.blue, margin: "0 0.3rem" }}>
                online payments
              </span>
            </li>
            <li>
              Send E-Rx along with
              <span style={{ color: colors.blue, margin: "0 0.3rem" }}>
                medicine & follow-up
              </span>
              reminders
            </li>
          </ul>
        ),
      },
      {
        img: Eimg2,
        heading: (
          <>
            <span style={isMobile ? styles.mobileSpan : styles.span}>
              {" "}
              Access your data
            </span>{" "}
            anytime & anywhere
          </>
        ),
        content: (
          <ul style={{ ...styles.ul }}>
            <li>
              Access your dashboard from anywhere
              <span style={{ color: colors.blue, margin: "0 0.3rem" }}>
                within seconds
              </span>
            </li>
            <li>
              Dedicated
              <span style={{ color: colors.blue, margin: "0 0.3rem" }}>
                app & dashboard
              </span>
              support
            </li>
          </ul>
        ),
      },
      {
        img: Eimg3,
        heading: (
          <>
            <span style={isMobile ? styles.mobileSpan : styles.span}>
              Manage multiple locations
            </span>{" "}
            and profiles on a single platform
          </>
        ),
        content: (
          <ul style={{ ...styles.ul }}>
            <li>
              Manage
              <span style={{ color: colors.blue, margin: "0 0.3rem" }}>
                multiple doctors{" "}
              </span>
            </li>
            <li>
              Provide selective access to
              <span style={{ color: colors.blue, margin: "0 0.3rem" }}>
                staff members
              </span>
              reminders
            </li>
            <li>
              Manage
              <span style={{ color: colors.blue, margin: "0 0.3rem" }}>
                multiple locations
              </span>
              from one single app
            </li>
          </ul>
        ),
      },
      {
        img: img1,
        heading: (
          <>
            Connect with your patients through our{" "}
            <span
              style={
                isMobile
                  ? styles.mobileSpan
                  : { ...styles.span, marginRight: "0.5rem" }
              }
            >
              integrated WhatsApp solution
            </span>{" "}
          </>
        ),
        content: (
          <ul style={{ ...styles.ul }}>
            <li>
              Customer
              <span style={{ color: colors.blue, margin: "0 0.3rem" }}>
                chat & management{" "}
              </span>
            </li>
            <li>
              Broadcast
              <span style={{ color: colors.blue, margin: "0 0.3rem" }}>
                marketing posters
              </span>
              on WhatsApp
            </li>
          </ul>
        ),
      },
    ],
  }

  const Clinicbody = {
    text: "Clinic",
    heading: [
      { img: individual, text: "Small Clinic" },
      { img: pharmacy, text: "Independent Practitioner" },
    ],
    features: [
      {
        img: img4,
        imgStyle: isMobile
          ? { objectFit: "contain", width: "70%" }
          : { objectFit: "contain", width: "50%" },
        heading: (
          <>
            <span style={isMobile ? styles.mobileSpan : styles.span}>
              WhatsApp Clinic
            </span>{" "}
            that's open 24/7 🏪
          </>
        ),
        content: (
          <ul style={{ ...styles.ul }}>
            <li>
              Allow patients to
              <span style={{ color: colors.blue, margin: "0 0.3rem" }}>
                book appointments
              </span>
            </li>
            <li>
              Send E-Rx along with
              <span style={{ color: colors.blue, margin: "0 0.3rem" }}>
                medicine & follow-up
              </span>
              reminders
            </li>
            <li>
              Collect
              <span style={{ color: colors.blue, margin: "0 0.3rem" }}>
                medical data
              </span>
            </li>
          </ul>
        ),
      },
      {
        img: img5,
        heading: (
          <>
            Power of both{" "}
            <span style={isMobile ? styles.mobileSpan : styles.span}>
              online & offline
            </span>{" "}
            in a single app 💪
          </>
        ),
        content: (
          <ul style={{ ...styles.ul }}>
            <li>
              Book & manage
              <span style={{ color: colors.blue, margin: "0 0.3rem" }}>
                offline appointments
              </span>
            </li>
            <li>
              Take
              <span style={{ color: colors.blue, margin: "0 0.3rem" }}>
                online consultations{" "}
              </span>
            </li>
            <li>
              Write
              <span style={{ color: colors.blue, margin: "0 0.3rem" }}>
                digital prescriptions{" "}
              </span>
            </li>
            <li>
              Manage
              <span style={{ color: colors.blue, margin: "0 0.3rem" }}>
                {" "}
                your schedule
              </span>
            </li>
          </ul>
        ),
      },
      {
        img: img6,
        heading: (
          <>
            Type less and
            <span
              style={
                isMobile
                  ? styles.mobileSpan
                  : { ...styles.span, marginLeft: "0.5rem" }
              }
            >
              write faster
            </span>{" "}
            prescriptions with AI 🤖
          </>
        ),
        content: (
          <ul style={{ ...styles.ul }}>
            <li>
              AI that
              <span style={{ color: colors.blue, margin: "0 0.3rem" }}>
                learns and grows
              </span>
              with you
            </li>
            <li>
              AI based suggestions for
              <span style={{ color: colors.blue, margin: "0 0.3rem" }}>
                drugs, tests & much more
              </span>
            </li>
          </ul>
        ),
      },
      // {
      //   img: img2,
      //   heading: (
      //     <>
      //       Bring your{" "}
      //       <span style={isMobile ? styles.mobileSpan : styles.span}>
      //         {" "}
      //         staff{" "}
      //       </span>{" "}
      //       on Paperplane with you{" "}
      //     </>
      //   ),
      //   content:
      //     "Shift management chores to your receptionist and focus solely on your medical practice.Take ownership of your clinic to add or modify assistance staff, while they get a dashboard of their own.",
      // },
      {
        img: img3,
        heading: (
          <>
            Build your own{" "}
            <span
              style={
                isMobile
                  ? styles.mobileSpan
                  : { ...styles.span, marginRight: "0.5rem" }
              }
            >
              clinic brand
            </span>{" "}
            📣
          </>
        ),
        content: (
          <ul style={{ ...styles.ul }}>
            <li>
              Personalised
              <span style={{ color: colors.blue, margin: "0 0.3rem" }}>
                {" "}
                clinic website
              </span>
              with{" "}
              <span style={{ color: colors.blue, margin: "0 0.3rem" }}>
                WhatsApp & Google Maps integration
              </span>
            </li>
            <li>
              Personally curated
              <span style={{ color: colors.blue, margin: "0 0.3rem" }}>
                marketing content & posters
              </span>
            </li>
            <li>
              Digital marketing support on
              <span style={{ color: colors.blue, margin: "0 0.3rem" }}>
                Instagram, Facebook & Google
              </span>
            </li>
          </ul>
        ),
      },
      {
        img: img1,
        heading: (
          <>
            Connect with your patients through our{" "}
            <span
              style={
                isMobile
                  ? styles.mobileSpan
                  : { ...styles.span, marginRight: "0.5rem" }
              }
            >
              integrated WhatsApp solution
            </span>{" "}
            💬
          </>
        ),
        content: (
          <ul style={{ ...styles.ul }}>
            <li>
              <span style={{ color: colors.blue, margin: "0 0.3rem" }}>
                Chat
              </span>
              with patients{" "}
            </li>
            <li>
              Send
              <span style={{ color: colors.blue, margin: "0 0.3rem" }}>
                prescriptions{" "}
              </span>
            </li>
            <li>
              Receive
              <span style={{ color: colors.blue, margin: "0 0.3rem" }}>
                online payments{" "}
              </span>
            </li>
            <li>
              Automatic
              <span style={{ color: colors.blue, margin: "0 0.3rem" }}>
                WhatsApp reminders
              </span>
            </li>
          </ul>
        ),
      },
    ],
  }

  const [checked, setChecked] = useState(false)
  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>
          {" "}
          Paperplane WhatsApp Clinic- Build your Digital Clinic on Whatsapp in
          less than 7 minutes
        </title>
      </Helmet>
      <Hero isMobile={isMobile} setOpen={setOpen} />
      {/* <Video /> */}
      <Carousel isMobile={isMobile} />
      <Grid item xs={12} style={{ margin: "1rem 0" }}>
        <Typography variant="h2" align="center" style={{ color: "light grey" }}>
          Trusted by 1000+ Clinics & Hospitals
        </Typography>
      </Grid>

      <Switch checked={checked} setChecked={setChecked} isMobile={isMobile} />

      <Body
        isMobile={isMobile}
        body={checked ? EnterpriseBody : Clinicbody}
        buttonProps={{
          label: "Start Now",
          onClick: () => setOpen(true),
        }}
        styles={styles}
      />
      {/* <BnplHeroSection isMobile={isMobile} /> 
      <Grid item xs={12} style={{marginTop: "4rem"}}></Grid> */}
      <AbhaHeroSection isMobile={isMobile} />
      <GrowthNumbers isMobile={isMobile}></GrowthNumbers>
      <Blogs blogList={blogList} />
      <Features isMobile={isMobile}></Features>

      {checked ? null : <GetApp isMobile={isMobile} isTab={isTab} />}
      <Demo isMobile={isMobile} />
      <AboutUs />
      <AsSeenOn isMobile={isMobile} />
      <BackedBy isMobile={isMobile} />
      <Testimonials isMobile={isMobile} />
      <ContactUs isMobile={isMobile} />
      <EnterpriseForm open={open} setOpen={setOpen} isMobile={isMobile} />

      <FAQ isMobile={isMobile} />
    </>
  )
}

export default withWidth()(Home)
